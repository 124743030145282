import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { Timeline } from 'antd'
import HTML2React from 'html2react'
import TimeAgo from 'react-timeago'
import ptBrStrings from 'react-timeago/lib/language-strings/pt-br'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import AppLayout from '../../layouts/AppLayout'

import { Container, Ranking } from './styles'
import { Card } from '../../components'

import api from '../../services/api'
import rankingHelper from '../../helper/ranking'

function Dashboard() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    tickets: 0,
    plugins: 0,
    licenses: 0,
    patrimony: 0,
    updates: [],
    ranking: [],
  })

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const result = await api.get('/user/dash')
      if (result !== null && result.data !== undefined && result.data !== null) {
        setData(result.data)
      }
    } catch (err) {
      // message.error('Ocorreu algum erro ao tentar buscar seus dados')
    }

    setLoading(false)
  }

  return (
    <AppLayout page="dashboard" title="Painel do cliente">
      <Container>
        <h5 className="mb-4">Dados gerais</h5>
        <Row>
          <Col sm={6} md={3}>
            <Card className="mb-4">
              <h1 className="mb-0">{loading ? '...' : data.plugins}</h1>
              <h6 className="m-0 text-muted">plugins ativos</h6>
            </Card>
          </Col>
          <Col sm={6} md={3}>
            <Card className="mb-4">
              <h1 className="mb-0">{loading ? '...' : data.licenses}</h1>
              <h6 className="m-0 text-muted">licenças ativas</h6>
            </Card>
          </Col>
          <Col sm={6} md={3}>
            <Card className="mb-4">
              <h1 className="mb-0">{loading ? '...' : data.tickets}</h1>
              <h6 className="m-0 text-muted">tickets abertos</h6>
            </Card>
          </Col>
          <Col sm={6} md={3}>
            <Card className="mb-4">
              <h1 className="mb-0">{loading ? '...' : rankingHelper.convert(data.patrimony)}</h1>
              <h6 className="m-0 text-muted">meu nível</h6>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={8}>
            <h5 className="mb-4">Atualizações recentes</h5>
            <Card className="mb-4">
              <Timeline pending={true}>
                {data != null &&
                  data.updates != null &&
                  data.updates.map((el) => (
                    <Timeline.Item key={el.id}>
                      <h5 className="mb-0">
                        {`${el.plugin.title}`} <small className="text-muted">{el.version}</small>
                      </h5>
                      <TimeAgo date={el.createdAt} formatter={buildFormatter(ptBrStrings)} />
                      <hr />
                      {HTML2React(el.body)}
                    </Timeline.Item>
                  ))}
              </Timeline>
            </Card>
          </Col>
          <Col md={4}>
            <h5 className="mb-4">Ranking de clientes</h5>
            {data.ranking &&
              data.ranking.map((el, idx) => (
                <Ranking key={`rnk-${idx}`}>
                  <img src={`https://minotar.net/avatar/${el.username}/80`} alt={`AtlasPlugins - ${el.username}`} />
                  <div>
                    <h4>{el.username}</h4>
                    <p>
                      cliente <TimeAgo date={el.createdAt} formatter={buildFormatter(ptBrStrings)} />
                    </p>
                  </div>
                  <span>{el.count} licenças ativas</span>
                </Ranking>
              ))}
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}

export default Dashboard
