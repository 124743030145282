import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Button, message } from 'antd'
import { Row, Col } from 'reactstrap'

import AppLayout from '../../layouts/AppLayout'
import { Container } from './styles'

import api from '../../services/api'
import { DiscordCard } from '../../components/ContactSection/styles'
import { FaDiscord } from 'react-icons/fa'

function SupportOpen({ history }) {
  const [ticketType, setTicketType] = useState('BUG')

  const [plugins, setPlugins] = useState([])
  const [loading, setLoading] = useState(false)

  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 30 },
  }

  useEffect(() => {
    loadPlugins()
  }, [])

  async function loadPlugins() {
    setLoading(true)

    try {
      const response = await api.get('/plugin/listar')
      if (response.data) {
        setPlugins(response.data)
      } else {
        message.error('Ocorreu um erro ao tentar se conectar com os servidores da Atlas Plugins')
      }
    } catch (err) {
      message.error('Ocorreu um erro ao tentar se conectar com os servidores da Atlas Plugins')
    }
    setLoading(false)
  }

  async function submit(values) {
    setLoading(true)

    try {
      await api.post('/ticket', values)

      message.success('Seu chamado foi enviado! Em breve iremos retornar')
      history.push('/dashboard/suporte')
    } catch (err) {
      if (err.response.data.message) {
        message.error(err.response.data.message)
      } else {
        message.error('Ocorreu um erro não esperado ao tentar abrir o ticket.')
      }
    }
    setLoading(false)
  }

  return (
    <AppLayout page="support" title="Abrir chamado">
      <Container>
        <h5>Abrir novo chamado</h5>
        <Row>
          <Col md={8} style={{ marginTop: '1%' }}>
            <Form {...layout} name="open-ticket" onFinish={submit}>
              <Form.Item label="Tipo" name="type" rules={[{ required: true, message: 'Informe o tipo do bug' }]}>
                <Select size="large" loading={loading} disabled={loading} onSelect={(value) => setTicketType(value)}>
                  <Select.Option value="FEATURE">Atualização de um plugin</Select.Option>
                  <Select.Option value="OTHERS">Assuntos financeiros ou outros assuntos</Select.Option>
                </Select>
              </Form.Item>
              {(ticketType === 'BUG' || ticketType === 'FEATURE') && (
                <Form.Item
                  label="Plugin"
                  name="plugin"
                  rules={[{ required: true, message: 'Informe o plugin com problema' }]}
                >
                  <Select size="large" showSearch loading={loading} disabled={loading}>
                    <Select.Option value="AtlasLicense">AtlasLicense</Select.Option>
                    {plugins.map((plugin) => (
                      <Select.Option value={plugin.title}>{plugin.title}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label="Assunto" name="subject" rules={[{ required: true, message: 'Informe o assunto' }]}>
                <Input size="large" disabled={loading} />
              </Form.Item>
              <Form.Item label="Mensagem" name="message" rules={[{ required: true, message: 'Informe a mensagem' }]}>
                <Input.TextArea
                  size="large"
                  showCount
                  minLength={35}
                  rows={5}
                  maxLength={400}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item style={{ marginLeft: '92%' }}>
                <Button type="primary" size="large" htmlType="submit" disabled={loading} loading={loading}>
                  Enviar
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col md={4}>
            <DiscordCard>
              <FaDiscord style={{ fontSize: '150px' }} />
              <div>
                <h3>Tenha um suporte mais eficiente</h3>
                <p>
                  Acesse nosso servidor de Discord clicando no botão abaixo e tenha um suporte mais eficiente com um
                  menor tempo para responder sua dúvida.
                  <br /> <b>Atenção:</b> Você precisa ter pelo menos um plugin para acessar o canal de suporte pelo
                  Discord.
                </p>
                <button onClick={() => window.open('https://discord.gg/J7MUSpC', 'blank')}>Entrar na Comunidade</button>
              </div>
            </DiscordCard>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}

export default SupportOpen
