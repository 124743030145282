import React, { useEffect } from 'react'

import { Modal } from 'antd'
import Dashboard from '../Dashboard'

export default function PaymentPending() {
  useEffect(() => {
    Modal.warning({
      title: 'Processando o pagamento',
      content:
        'O seu pagamento está sendo processado pelo gateway de pagamento, assim que ele for aprovado seus plugins serão liberados.',
    })
  }, [])

  return <Dashboard />
}
