export function setCategory(payload) {
  return {
    type: "SET_CATEGORY",
    payload,
  };
}

export function setType(payload) {
  return {
    type: "SET_TYPE",
    payload,
  };
}

export function setSort(payload) {
  return {
    type: "SET_SORT",
    payload,
  };
}
