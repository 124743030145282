import React, { useEffect } from 'react'

import { Modal } from 'antd'
import Dashboard from '../Dashboard'

export default function PaymentSuccess() {
  useEffect(() => {
    Modal.success({
      title: 'Pagamento efetuado com sucesso',
      content: 'Obrigado por comprar conosco, seus plugins serão ativados em alguns minutos.',
    })
  }, [])

  return <Dashboard />
}
