export function addProduct(payload) {
  return {
    type: "ADD_PRODUCT",
    payload,
  };
}

export function removeProduct(id) {
  return {
    type: "REMOVE_PRODUCT",
    payload: id,
  };
}

export function addLicense(id) {
  return {
    type: "ADD_LICENSE",
    payload: id,
  };
}

export function removeLicense(payload) {
  return {
    type: "REMOVE_LICENSE",
    payload,
  };
}

export function editLicense(payload) {
  return {
    type: "EDIT_LICENSE",
    payload,
  };
}

export function setCurrency(payload) {
  return {
    type: "SET_CURRENCY",
    payload,
  };
}
