import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Timeline, Drawer, Tabs } from 'antd'
import HTML2React from 'html2react'
import TimeAgo from 'react-timeago'
import ptBrStrings from 'react-timeago/lib/language-strings/pt-br'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import { GrShare } from 'react-icons/gr'

import { Details } from './styles'

import { addProduct } from '../../store/actions/cart.actions'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

function SearchItem({ data, onDetailsClick }) {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)

  function addToCart() {
    dispatch(
      addProduct({
        id: data.id,
        name: data.title,
        promotional: data.promotional,
        image: data.image,
        price: parseFloat(data.promotional) > 0 ? parseFloat(data.promotional) : parseFloat(data.price),
        licenses: [{ ip: '127.0.0.0', port: '25565' }],
      })
    )

    onDetailsClick()
  }

  function download() {
    window.open(`https://api.atlasplugins.com/v1/plugin/${data.id}/download`, 'blank')

    onDetailsClick()
  }

  let { id } = useParams()

  useEffect(() => {
    if (id && data.title.toLowerCase() === id.toLowerCase()) setVisible(true)
  }, [id, data.title])

  return (
    <>
      <li key={data.id}>
        <img src={data.image} alt="AtlasPlugins - " />
        <h5>{data.title}</h5>
        <span>
          R$
          {parseFloat(data.promotional) > 0
            ? parseFloat(data.promotional).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : parseFloat(data.price).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
        </span>
        <button
          onClick={() => {
            setVisible(true)
          }}
        >
          Detalhes
        </button>
      </li>
      <Drawer
        title="Detalhes do Plugin"
        placement="bottom"
        closable={false}
        height="80vh"
        style={{ zIndex: '9999999999' }}
        zIndex="9999999999"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Details>
          <img src={data.image} alt="Atlas Plugins" />
          <div>
            <h4>
              {data.title} <small className="text-muted">{data.version}</small>{' '}
              <GrShare
                style={{ fontSize: '15px', cursor: 'pointer' }}
                onClick={async () => {
                  await navigator.clipboard.writeText(`https://www.atlasplugins.com/plugins/${data.title}`)
                }}
              />
            </h4>
            <h6>{data.category.name}</h6>
            <h5
              style={{
                display: data.category.type === 'PAID' ? 'block' : 'none',
              }}
            >
              {parseFloat(data.promotional) > 0 ? (
                <span>
                  R$
                  {parseFloat(data.promotional).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  <small className="ml-2 text-muted" style={{ textDecoration: 'line-through' }}>
                    R$
                    {parseFloat(data.price).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </small>
                </span>
              ) : (
                <span>
                  R$
                  {parseFloat(data.price).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              )}
            </h5>
            {data.category.type === 'PAID' ? (
              <button
                className="mt-4"
                onClick={() => {
                  addToCart()
                  setVisible(false)
                }}
              >
                comprar
              </button>
            ) : (
              <button className="mt-4" onClick={download}>
                download
              </button>
            )}
            <b>{data.downloads}</b> cópias vendidas
          </div>
        </Details>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Visão geral" key="1">
            {HTML2React(data.description)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Comandos" key="2">
            {HTML2React(data.commands)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Permissões" key="3">
            {HTML2React(data.permissions)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Atualizações" key="4">
            <br />
            {data.updates.length > 0 ? (
              <Timeline pending={true}>
                {data.updates.map((el) => (
                  <Timeline.Item key={el.id}>
                    <h5 className="mb-0">{`${el.version}`}</h5>
                    <TimeAgo date={el.createdAt} formatter={buildFormatter(ptBrStrings)} />
                    <hr />
                    {HTML2React(el.body)}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <h4>Não há atualizações para este plugin</h4>
            )}
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </>
  )
}

export default SearchItem
