/*

import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  position: relative;
  //background-color: var(--quaternary);
  background-color: #00203d;
  height: 240px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 10px;

  div {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);

    h1 {
      color: white;
      b {
        font-weight: 900;
      }
      span {
        font-weight: 300;
        font-size: 20px;
      }

      margin-bottom: 20px;
      font-size: 42px;
      line-height: 42px;
    }

    button {
      background: var(--white);
      color: var(--tertiary);
      padding: 8px 15px;
      border-radius: 10px;
    }
  }

  ${media.lessThan('medium')`
    margin-top: 0;
    height: 180px;

    div {
      h1 {
        font-size: 22px;
        line-height: 22px;
      }
    }
  `}

  ${media.between('medium', 'large')`
    margin-top: 0;
    height: 180px;

    div {
      h1 {
        font-size: 22;
        line-height: 22px;
      }
    }
  `}
`

export const Image = styled.img.attrs({
  src: require('../../assets/images/halloween-pumpkin.png'),
  alt: 'Atlas Plugins - Ghost',
})`
  position: absolute;
  top: -10px;
  right: -20px;
  height: 260px;

  ${media.lessThan('medium')`
    display: none;
  `}

  ${media.between('medium', 'large')`
    display: none;
  `}
`


*/

/*

BLACK FRIDAY SALE

import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  position: relative;
  //background-color: var(--quaternary);
  background-color: #000000;
  height: 240px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
  div {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    h1 {
      color: white;
      b {
        font-weight: 900;
      }
      span {
        font-size: 19px;
      }
      margin-bottom: 20px;
      font-size: 42px;
      line-height: 42px;
    }
    button {
      background: var(--white);
      color: var(--tertiary);
      padding: 8px 15px;
      border-radius: 10px;
    }
  }
  ${media.lessThan('medium')`
    margin-top: 0;
    height: 180px;
    div {
      h1 {
        font-size: 22px;
        line-height: 22px;
      }
    }
  `}
  ${media.between('medium', 'large')`
    margin-top: 0;
    height: 180px;
    div {
      h1 {
        font-size: 22;
        line-height: 22px;
      }
    }
  `}
`

export const Image = styled.img.attrs({
  src: 'https://i.imgur.com/smgJMhV.png',
  alt: 'Atlas Plugins - Ghost',
})`
  position: absolute;
  top: -10px;
  right: -20px;
  height: 260px;
  ${media.lessThan('medium')`
    display: none;
  `}
  ${media.between('medium', 'large')`
    display: none;
  `}
`


import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  position: relative;
  //background-color: var(--quaternary);
  background-color: #be1614;
  height: 240px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
  div {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    h1 {
      color: white;
      b {
        font-weight: 900;
      }
      span {
        font-size: 19px;
      }
      margin-bottom: 20px;
      font-size: 42px;
      line-height: 42px;
    }
    button {
      background: var(--white);
      color: var(--tertiary);
      padding: 8px 15px;
      border-radius: 10px;
    }
  }
  ${media.lessThan('medium')`
    margin-top: 0;
    height: 180px;
    div {
      h1 {
        font-size: 22px;
        line-height: 22px;
      }
    }
  `}
  ${media.between('medium', 'large')`
    margin-top: 0;
    height: 180px;
    div {
      h1 {
        font-size: 22;
        line-height: 22px;
      }
    }
  `}
`

export const Image = styled.img.attrs({
  src: 'https://64.media.tumblr.com/0ee4e82109b910600a2720afd1adc8ab/tumblr_mfj2hcfsQe1rfjowdo1_500.gif',
  alt: 'Atlas Plugins - Ghost',
})`
  position: absolute;
  top: -10px;
  right: -20px;
  height: 260px;
  ${media.lessThan('medium')`
    display: none;
  `}
  ${media.between('medium', 'large')`
    display: none;
  `}
`
*/

import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  position: relative;
  background-color: #007cff;
  height: 240px;
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
  div {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    h1 {
      color: white;
      b {
        font-weight: 900;
      }
      span {
        font-weight: 300;
      }
      margin-bottom: 20px;
      font-size: 42px;
      line-height: 42px;
    }
    button {
      background: var(--white);
      color: var(--tertiary);
      padding: 8px 15px;
      border-radius: 10px;
    }
  }
  ${media.lessThan('medium')`
    margin-top: 0;
    height: 180px;
    div {
      h1 {
        font-size: 22px;
        line-height: 22px;
      }
    }
  `}
  ${media.between('medium', 'large')`
    margin-top: 0;
    height: 180px;
    div {
      h1 {
        font-size: 22;
        line-height: 22px;
      }
    }
  `}
`

export const Image = styled.img.attrs({
  src: require('../../assets/images/cake.png'),
  alt: 'Atlas Plugins - Ghost',
})`
  position: absolute;
  top: -10px;
  right: -20px;
  height: 260px;
  ${media.lessThan('medium')`
    display: none;
  `}
  ${media.between('medium', 'large')`
    display: none;
  `}
`
