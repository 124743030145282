/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Card, Button, Row, Col, Input, Form, Upload, Tag, message as msg } from 'antd'
import Linkify from 'react-linkify'
import nl2br from 'react-nl2br'

import AppLayout from '../../layouts/AppLayout'

import { getToken, getUser } from '../../guard'

import api from '../../services/api'
import supportHelper from '../../helper/support'

function SupportView({ match }) {
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState('')
  const [data, setData] = useState({
    messages: [],
    files: [],
    user: {
      name: '',
    },
  })

  async function getData(withLoading = true, scroller = true) {
    if (withLoading) {
      setLoading(true)
    }

    try {
      const result = await api.get('/ticket/' + match.params.id)

      setData(result.data)

      if (scroller) {
        setTimeout(() => {
          scrollToBottom()
        }, 500)
      }
    } catch (err) {
      msg.error('Ocorreu um erro ao tentar buscar os tickets')
    }

    if (withLoading) {
      setLoading(false)
    }
  }

  async function submit() {
    setLoading(true)

    if (message === '') return msg.info('Informe a mensagem')

    try {
      await api.put('/ticket/message/' + match.params.id, { message })

      setMessage('')
      await getData()
    } catch (err) {
      msg.error('Não foi possível responder o ticket')
    }

    setLoading(false)
  }

  function scrollToBottom() {
    var div = document.querySelector('.chat')

    if (div) {
      div.scrollTop = div.scrollHeight
    }
  }

  async function closeTicket() {
    setLoading(true)

    try {
      await api.delete('/ticket/' + match.params.id)

      msg.success('Ticket fechado')
      await getData()
    } catch (err) {
      msg.error('Não foi possível responder o ticket')
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()

    var interval = setInterval(() => {
      var div = document.querySelector('.chat')

      if (!div) {
        clearInterval(interval)
      }

      getData(false, false)
    }, 7000)
  }, [match])

  return (
    <AppLayout page="support" title="Detalhes do chamado">
      <h5>Chamado #{match.params.id}</h5>
      <Row gutter={16}>
        <Col md={8}>
          <Card title="Detalhes do ticket" className="mt-4" bordered={false} loading={loading}>
            <p className="mb-0">
              <b>Status:</b> {supportHelper.status(data.status)}
            </p>
            <p className="mb-0">
              <b>Última atualização:</b> {new Date(data.updatedAt).toLocaleString('pt-BR')}
            </p>
            <p>
              <b>Criado em:</b> {new Date(data.createdAt).toLocaleString('pt-BR')}
            </p>
            <br />
            {(getUser().level === 'SUPPORT' || getUser().level === 'ADMINISTRATOR') && (
              <div>
                <p className="mb-0">
                  <b>Nome de usuário:</b> {data.user.username}
                </p>
                <p className="mb-0">
                  <b>E-mail:</b> {data.user.email}
                </p>
                <p>
                  <b>Discord:</b> {data.user.discord}
                </p>
                {data.status !== 'CLOSED' && (
                  <>
                    <br />
                    <Button block type="danger" onClick={closeTicket}>
                      Fechar ticket
                    </Button>
                  </>
                )}
              </div>
            )}
          </Card>
          <Card title="Arquivos anexados" className="mt-4" bordered={false} loading={loading}>
            <Upload.Dragger
              name="file"
              multiple={true}
              previewFile={true}
              method="put"
              locale={{
                uploadError: 'Ocorreu um erro',
                uploading: 'Enviando...',
              }}
              action={`https://api.atlasplugins.com/v1/ticket/${match.params.id}/files`}
              headers={{
                authorization: `Bearer ${getToken()}`,
              }}
            >
              <p className="ant-upload-text">Clique ou arraste os arquivos para upload</p>
              <p className="ant-upload-hint">
                Você pode arrastar um ou mais arquivos. Não é necessário responder o ticket, o upload é automático.
              </p>
            </Upload.Dragger>
            <br />
            {data.files.map((file) => (
              <Tag
                onClick={() => window.open(`https://api.atlasplugins.com/v1/file/download/${file.source}`, '_blank')}
              >
                {file.originalName} upado em {new Date(file.createdAt).toLocaleString('pt-BR')} (clique para baixar)
              </Tag>
            ))}
          </Card>
        </Col>
        <Col md={16}>
          <Card title={`Chat - ${data.subject}`} className="mt-4" bordered={false} loading={loading}>
            <div className="chat">
              {data.messages.map((el) => (
                <div
                  className={`chat-message ${
                    el.author !== (['SUPPORT', 'ADMINISTRATOR'].includes(getUser().level) ? 'OWNER' : 'STAFF') &&
                    'chat-me'
                  }`}
                >
                  {el.author !== (['SUPPORT', 'ADMINISTRATOR'].includes(getUser().level) ? 'OWNER' : 'STAFF') && (
                    <div className="chat-message-time mr-2">
                      {new Date(el.createdAt).toLocaleString('pt-br')}
                      {el.senderName && (
                        <>
                          <br />
                          por {el.senderName}
                        </>
                      )}
                    </div>
                  )}
                  <div className="chat-message-body">
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={decoratedHref}
                          key={key}
                          style={{ color: '#fff', textDecoration: 'underline' }}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      {nl2br(el.message)}
                    </Linkify>
                  </div>
                  {el.author === (['SUPPORT', 'ADMINISTRATOR'].includes(getUser().level) ? 'OWNER' : 'STAFF') && (
                    <div className="chat-message-time">
                      {new Date(el.createdAt).toLocaleString('pt-br')}
                      {el.senderName && (
                        <>
                          <br />
                          por {el.senderName}
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div>
              <Form.Item>
                <Input.TextArea
                  size="large"
                  style={{ marginTop: '10px' }}
                  showCount
                  minLength={35}
                  rows={5}
                  maxLength={400}
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
              </Form.Item>
              <Form.Item>
                <Button className="mr-2" onClick={submit} loading={loading} type="primary">
                  Responder
                </Button>
              </Form.Item>
            </div>
          </Card>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default SupportView
