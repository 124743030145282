/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { usePluginSectionContext } from '../../contexts/PluginSectionProvider'
import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/src/owl.carousel.css'

import ShimmerCategory from '../ShimmerCategory'

import { Container, Category, Label } from './styles'

import api from '../../services/api'
import { setCategory } from '../../store/actions/settings.actions'

function Categories() {
  const dispatch = useDispatch()
  const settings = useSelector((state) => state.settings)

  const { setMorePlugins, setPage } = usePluginSectionContext()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const options = {
    items: 7,
    nav: false,
    rewind: true,
    autoplay: false,
    responsive: {
      0: {
        items: 2,
      },
      480: {
        items: 3,
      },
      768: {
        items: 7,
      },
    },
  }

  async function getData() {
    setLoading(true)

    try {
      const result = await api.get(`/category/${settings.type}/type`)

      setData(result.data)
    } catch (err) {
      message.error('Não foi possível buscar as categorias')
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [settings.type])

  return (
    <Container id="products">
      <OwlCarousel options={options} id="owl-light">
        <Category
          actived={settings.category === 'all'}
          onClick={() => {
            setPage(0)
            setMorePlugins(true)
            dispatch(setCategory('all'))
          }}
        >
          <i className="fas fa-asterisk"></i>
          <Label actived={settings.category === 'all'}>Todos</Label>
        </Category>
        {loading ? (
          <>
            <ShimmerCategory />
            <ShimmerCategory />
            <ShimmerCategory />
            <ShimmerCategory />
            <ShimmerCategory />
            <ShimmerCategory />
          </>
        ) : (
          data.map((el) => (
            <Category
              key={`category-${el.id}`}
              actived={settings.category === el.id}
              onClick={() => {
                setPage(0)
                setMorePlugins(true)
                dispatch(setCategory(el.id))
              }}
            >
              <i className={el.icon}></i>
              <Label actived={settings.category === el.id}>{el.name}</Label>
            </Category>
          ))
        )}
      </OwlCarousel>
    </Container>
  )
}

export default Categories
