import React from 'react'
import { isMobile } from 'react-device-detect'
import { FiHome, FiPackage, FiHelpCircle, FiShoppingBag, FiServer } from 'react-icons/fi'
import { BiMailSend } from 'react-icons/bi'

import { Container } from './styles'
import { Link } from 'react-router-dom'

function AppMenu({ page }) {
  return (
    <Container id="app-nav">
      <li className={`${page === 'dashboard' && !isMobile && 'actived'} ${isMobile && 'px-3'}`}>
        {isMobile ? (
          <Link to="/dashboard">
            <FiHome className="mr-0" />
          </Link>
        ) : (
          <>
            <FiHome />
            <Link to="/dashboard">Dashboard</Link>
          </>
        )}
      </li>
      <li className={`${page === 'plugins' && !isMobile && 'actived'} ${isMobile && 'px-3'}`}>
        {isMobile ? (
          <Link to="/dashboard/plugins">
            <FiPackage className="mr-0" />
          </Link>
        ) : (
          <>
            <FiPackage />
            <Link to="/dashboard/plugins">Meus plugins</Link>
          </>
        )}
      </li>
      <li className={`${page === 'support' && !isMobile && 'actived'} ${isMobile && 'px-3'}`}>
        {isMobile ? (
          <Link to="/dashboard/suporte">
            <FiHelpCircle className="mr-0" />
          </Link>
        ) : (
          <>
            <FiHelpCircle />
            <Link to="/dashboard/suporte">Suporte</Link>
          </>
        )}
      </li>
      <li className={`${page === 'purchases' && !isMobile && 'actived'} ${isMobile && 'px-3'}`}>
        {isMobile ? (
          <Link to="/dashboard/compras">
            <FiShoppingBag className="mr-0" />
          </Link>
        ) : (
          <>
            <FiShoppingBag />
            <Link to="/dashboard/compras">Minhas compras</Link>
          </>
        )}
      </li>
      <li className={`${page === 'server' && !isMobile && 'actived'} ${isMobile && 'px-3'}`}>
        {isMobile ? (
          <Link to="/dashboard/servidor">
            <FiServer className="mr-0" />
          </Link>
        ) : (
          <>
            <FiServer />
            <Link to="/dashboard/servidor">Meu servidor</Link>
          </>
        )}
      </li>
      <li className={`${page === 'sms' && !isMobile && 'actived'} ${isMobile && 'px-3'}`}>
        {isMobile ? (
          <Link to="/dashboard/sms">
            <BiMailSend className="mr-0" />
          </Link>
        ) : (
          <>
            <BiMailSend />
            <Link to="/dashboard/sms">SMS</Link>
          </>
        )}
      </li>
    </Container>
  )
}

export default AppMenu
