import styled from "styled-components";

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;

  img {
    max-width: 60px;
    margin-right: 60px;
    margin-left: 40px;
  }

  h4 {
    margin: 0;
  }

  h6 {
    margin: 0;
  }

  h5 {
    margin: 10px 0;
  }

  button {
    padding: 5px 15px;
    background: var(--primary);
    border-radius: 5px;
    color: var(--white);
    margin-right: 15px;
  }
`;
