import React, { useRef, useState } from 'react'
import { notification, message } from 'antd'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-recaptcha'
import { motion } from 'framer-motion'

import { Page, Grid, Col, Card, Flex, Divider } from './styles'

import { RiLockPasswordFill, RiUser5Line } from 'react-icons/ri'

import api from '../../services/api'
import { setToken, setUser } from '../../guard'

import logoSrc from '../../assets/images/logo-white.png'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { Helmet } from 'react-helmet'

function Login({ history }) {
  const transition = {
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96],
  }

  const backVariants = {
    exit: { x: -100, opacity: 0, transition },
    enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
  }

  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const recaptchaRef = useRef(null)

  function processCaptcha(event) {
    event.preventDefault()
    recaptchaRef.current.execute()
  }

  async function submit(response) {
    const username = document.getElementById('username-field').value
    const password = document.getElementById('password-field').value

    if (!username) return message.info('Informe o usuário')
    if (!password) return message.info('Informe sua senha')

    //if (!response) return message.info('Captcha não resolvido')

    setLoading(true)

    try {
      const result = await api.post('/user/session', {
        username,
        password,
        response,
      })
      const { user, token } = result.data

      setUser(user)
      setToken(token)

      history.push('/dashboard')

      message.success(`Bem-vindo de volta, ${username}`)
    } catch (err) {
      window.grecaptcha.reset()
      if (err.response) {
        notification.error({
          message: 'Revise seus dados',
          description: err.response.data.message,
          placement: 'bottomRight',
        })
        //message.error(err.response.data.message)
      } else {
        message.error('Ops!, não foi possível se conectar com os servidores da atlas plugins.')
      }
    }

    setLoading(false)
  }

  return (
    <>
      <Helmet>
        <title>Fazer login - Atlas Plugins</title>
      </Helmet>
      <Page>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LdearEZAAAAAKktAO99pbj3B2ZN2aeScIlWoXhU"
          size="invisible"
          render="explicit"
          verifyCallback={submit}
        />

        <Grid>
          <Col left>
            <motion.div variants={backVariants}>
              <motion.img layoutId="atlasLogo" src={logoSrc} alt="Atlas Loja - Login" />
              <motion.h1 layoutId="atlasHeading">Faça seu login na plataforma</motion.h1>
            </motion.div>
          </Col>
          <Col>
            <Card onSubmit={processCaptcha} layoutId="card">
              <Input
                leftIcon
                Icon={RiUser5Line}
                inputProps={{
                  placeholder: 'Nome de usuário ou endereço de e-mail',
                  id: 'username-field',
                }}
                value={username}
                onChange={setUsername}
              />
              <Input
                leftIcon
                Icon={RiLockPasswordFill}
                inputProps={{
                  placeholder: 'Informe sua senha',
                  id: 'password-field',
                }}
                isPassword
                value={password}
                onChange={setPassword}
              />

              <Link to="/recuperar-senha">Esqueci minha senha</Link>
              <Button
                backgroundColor="var(--primary)"
                type="submit"
                className="g-recaptcha"
                color="#fff"
                loading={loading}
              >
                Fazer login
              </Button>
              <Divider />
              <Button backgroundColor="#000" color="#fff" onClick={() => history.push('/cadastro')}>
                Criar uma nova conta
              </Button>
              <Flex>
                {/* <Button
                backgroundColor="rgba(59, 89, 152, 0.2)"
                color="#3b5998"
                loading={facebookLoading}
                onClick={loginWithFacebook}
              >
                Facebook
              </Button>
              <Button
                backgroundColor="rgba(199, 22, 16, 0.2)"
                color="#c71610"
                loading={googleLoading}
                onClick={loginWithGoogle}
              >
                Google+
            </Button> */}
              </Flex>
            </Card>
          </Col>
        </Grid>
      </Page>
    </>
  )
}

export default Login
