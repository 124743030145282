import styled from "styled-components";

export const Container = styled.div``;

export const Ranking = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px #0000000b;
  margin-bottom: 10px;

  img {
    border-radius: 80px;
  }

  div {
    margin-left: 20px;
    margin-right: auto;
    p {
      margin: 0;
      color: var(--muted);
    }

    h4 {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  span {
    background: var(--secondary);
    padding: 5px 15px;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
  }
`;
