import styled, { keyframes } from "styled-components";

const floatingCircular = keyframes`
   0% {
    -webkit-transform: rotate(0) translateX(5px) rotate(0);
	transform: rotate(0) translateX(5px) rotate(0)
   }
   100%{ 
    -webkit-transform: rotate(360deg) translateX(5px) rotate(-360deg);
	transform: rotate(360deg) translateX(5px) rotate(-360deg)
   }
`;

export const ServerUploader = styled.div`
  width: 270px;
  height: 270px;
  display: flex;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > img {
    width: 100%;
  }
`;

export const FloatingDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  animation: ${floatingCircular} 3s linear infinite;
  animation-timing-function: linear;

  > img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;
