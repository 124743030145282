import React from 'react'

import DefaultLayout from '../../layouts/DefaultLayout'
import HomePresentation from '../../components/HomePresentation'
import PluginHeader from '../../components/PluginHeader'
import Categories from '../../components/Categories'
import PluginsList from '../../components/PluginsList'
import PartnersSection from '../../components/PartnersSection'
import AboutSection from '../../components/AboutSection'
import ContactSection from '../../components/ContactSection'

import { PluginSectionProvider } from '../../contexts/PluginSectionProvider'

export default function Home() {
  return (
    <DefaultLayout title="Página Inicial" pageUrl="/">
      <HomePresentation />
      <PluginHeader />
      <PluginSectionProvider>
        <Categories />
        <PluginsList />
      </PluginSectionProvider>
      <PartnersSection />
      <AboutSection />
      <ContactSection />
    </DefaultLayout>
  )
}
