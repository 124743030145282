import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'
import { MdAlternateEmail } from 'react-icons/md'

import { message, notification } from 'antd'
import { motion } from 'framer-motion'

import { Page, Card } from './styles'

import Input from '../../components/Input'
import Button from '../../components/Button'

import api from '../../services/api'

import logoSrc from '../../assets/images/logo-white.png'

function Recover() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const recaptchaRef = useRef(null)

  const transition = {
    duration: 1,
    ease: [0.43, 0.13, 0.23, 0.96],
  }

  const backVariants = {
    exit: { y: -100, opacity: 0, transition },
    enter: { y: 0, opacity: 1, transition: { delay: 0, ...transition } },
  }

  function processCaptcha(event) {
    event.preventDefault()
    setLoading(true)
    recaptchaRef.current.execute()
  }

  async function submit(response) {
    if (typeof response !== 'string') return

    const email = document.getElementById('email-field').value
    if (!email) {
      setLoading(false)
      return message.info('Informe o e-email')
    }

    try {
      await api.post('/user/recover', { email, response })

      setEmail('')
      notification.success({
        message: 'Pronto!',
        description:
          'Um e-mail foi enviado com instruções para a troca de senha. Todas suas sessões foram encerradas por segurança.',
        placement: 'bottomRight',
      })
    } catch (err) {
      window.grecaptcha.reset()
      message.error(err.response.data.message)
    }

    setLoading(false)
  }

  return (
    <Page>
      <Recaptcha
        ref={recaptchaRef}
        sitekey="6LdearEZAAAAAKktAO99pbj3B2ZN2aeScIlWoXhU"
        size="invisible"
        render="explicit"
        verifyCallback={submit}
      />

      <motion.img variants={backVariants} src={logoSrc} alt="Atlas Loja - Logo" />
      <motion.h1 variants={backVariants}>Esqueci minha senha</motion.h1>
      <Card onSubmit={processCaptcha} layoutId="card">
        <p>Para recuperar sua senha, informe abaixo seu e-mail cadastrado na plataforma</p>
        <Input
          leftIcon
          topLeftIcon="20px"
          Icon={MdAlternateEmail}
          inputProps={{
            placeholder: 'Informe seu e-mail',
            id: 'email-field',
          }}
          value={email}
          onChange={setEmail}
        />
        <Button
          backgroundColor="var(--primary)"
          type="submit"
          className="g-recaptcha"
          color="#fff"
          loading={loading}
          onClick={submit}
        >
          Recuperar senha
        </Button>
      </Card>
      <Link to="/login">Voltar ao Login</Link>
    </Page>
  )
}

export default Recover
