import React, { useState } from 'react'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { Timeline, Drawer, Tabs, Tag } from 'antd'
import HTML2React from 'html2react'
import TimeAgo from 'react-timeago'
import ptBrStrings from 'react-timeago/lib/language-strings/pt-br'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import { Container, Image, Body, CategoryName, Title, Price, Buttons, Button, Details } from './styles'

import { GrShare } from 'react-icons/gr'

import { addProduct } from '../../store/actions/cart.actions'

function Plugin({ data }) {
  const [visible, setVisible] = useState(false)

  const dispatch = useDispatch()

  function addToCart() {
    dispatch(
      addProduct({
        id: data.id,
        name: data.title,
        promotional: data.promotional,
        price: parseFloat(data.promotional) > 0 ? parseFloat(data.promotional) : parseFloat(data.price),
        image: data.image,
        licenses: [{ ip: '127.0.0.0', port: '25565' }],
      })
    )
  }

  function download() {
    window.open(`https://api.atlasplugins.com/v1/plugin/${data.id}/download`, 'blank')
  }

  return (
    <Container>
      <Image>
        <img src={data.image} alt="Atlas Plugins - Minecraft Logo" />
      </Image>
      <Body>
        <CategoryName>
          {data.category.name}{' '}
          {data.title === 'AtlasSMS' && (
            <Tag style={{ margin: '10px' }} color="blue">
              Exclusivo
            </Tag>
          )}
        </CategoryName>
        <Title>{data.title}</Title>
        {data.category.type === 'PAID' ? (
          data.promotional > 0 ? (
            <Price style={{ fontSize: 18, lineHeight: '18px' }}>
              <small className="text-muted" style={{ textDecoration: 'line-through' }}>
                de R$
                {parseFloat(data.price).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </small>{' '}
              <br />
              <span style={{ fontSize: 24, lineHeight: '24px' }}>
                R$
                {parseFloat(data.promotional).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </Price>
          ) : (
            <Price>
              R$
              {parseFloat(data.promotional > 0 ? data.promotional : data.price).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Price>
          )
        ) : null}
        <Buttons>
          {data.category.type === 'PAID' ? (
            <Button onClick={addToCart}>comprar</Button>
          ) : (
            <Button onClick={download}>download</Button>
          )}
          <Button transparent onClick={() => setVisible(true)}>
            <IoIosInformationCircleOutline />
          </Button>
        </Buttons>
      </Body>
      <Drawer
        title="Detalhes do Plugin"
        placement="bottom"
        closable={false}
        height="80vh"
        style={{ zIndex: '999999999' }}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Details>
          <img src={data.image} alt="Atlas Plugins" />
          <div>
            <h4>
              {data.title} <small className="text-muted">{data.version}</small>
              <GrShare
                style={{ fontSize: '15px', cursor: 'pointer', marginLeft: '5px' }}
                onClick={async () => {
                  await navigator.clipboard.writeText(`https://www.atlasplugins.com/plugins/${data.title}`)
                }}
              />
            </h4>
            <h6>{data.category.name}</h6>
            <h5
              style={{
                display: data.category.type === 'PAID' ? 'block' : 'none',
              }}
            >
              {parseFloat(data.promotional) > 0 ? (
                <span>
                  R$
                  {parseFloat(data.promotional).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  <small className="ml-2 text-muted" style={{ textDecoration: 'line-through' }}>
                    R$
                    {parseFloat(data.price).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </small>
                </span>
              ) : (
                <span>
                  R$
                  {parseFloat(data.price).toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              )}
            </h5>
            {data.category.type === 'PAID' ? (
              <button
                className="mt-4"
                onClick={() => {
                  addToCart()
                  setVisible(false)
                }}
              >
                comprar
              </button>
            ) : (
              <button className="mt-4" onClick={download}>
                download
              </button>
            )}
            <b>{data.downloads}</b> cópias vendidas
          </div>
        </Details>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Visão geral" key="1">
            {HTML2React(data.description)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Comandos" key="2">
            {HTML2React(data.commands)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Permissões" key="3">
            {HTML2React(data.permissions)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Atualizações" key="4">
            <br />
            {data.updates.length > 0 ? (
              <Timeline pending={true}>
                {data.updates.map((el) => (
                  <Timeline.Item key={el.id}>
                    <h5 className="mb-0">{`${el.version}`}</h5>
                    <TimeAgo date={el.createdAt} formatter={buildFormatter(ptBrStrings)} />
                    <hr />
                    {HTML2React(el.body)}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <h4>Não há atualizações para este plugin</h4>
            )}
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </Container>
  )
}

export default Plugin
