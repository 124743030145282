import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FiTrash } from 'react-icons/fi'
import { Drawer, Row, Col, Button, Input } from 'antd'

import { Container } from './styles'

import { removeProduct, addLicense, editLicense, removeLicense } from '../../store/actions/cart.actions'

function CartItem({ data: { id, name, licenses, image } }) {
  const dispatch = useDispatch()

  const [visible, setVisible] = useState(false)

  return (
    <React.Fragment>
      <Drawer
        title="Configurar licenças"
        width={400}
        placement="right"
        closable={false}
        style={{ zIndex: '999999999' }}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Row gutter={16}>
          <Col md={17}>
            <Input
              size="large"
              placeholder="IP"
              value={licenses[0].ip}
              onChange={(e) => {
                var { value } = e.target

                dispatch(
                  editLicense({
                    id,
                    idx: 0,
                    ip: value,
                    port: licenses[0].port,
                  })
                )
              }}
              className="mb-3"
            />
          </Col>
          <Col md={7}>
            <Input
              size="large"
              placeholder="Porta"
              value={licenses[0].port}
              onChange={(e) => {
                var { value } = e.target
                var reg = /^-?\d*(\.\d*)?$/
                if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                  dispatch(editLicense({ id, idx: 0, ip: licenses[0].ip, port: value }))
                }
              }}
              className="mb-3"
            />
          </Col>
        </Row>
        <br />
        <h5>Licenças adicionais</h5>
        {licenses.map((elem, idx) => {
          if (idx > 0) {
            return (
              <Row gutter={16} key={`license-${idx}`}>
                <Col md={13}>
                  <Input
                    size="large"
                    placeholder="IP"
                    value={elem.ip}
                    onChange={(e) => {
                      var { value } = e.target

                      dispatch(editLicense({ id, idx, ip: value, port: elem.port }))
                    }}
                    className="mb-3"
                  />
                </Col>
                <Col md={7}>
                  <Input
                    size="large"
                    placeholder="Porta"
                    value={elem.port}
                    className="mb-3"
                    onChange={(e) => {
                      var { value } = e.target
                      var reg = /^-?\d*(\.\d*)?$/
                      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
                        dispatch(editLicense({ id, idx, ip: elem.ip, port: value }))
                      }
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Button
                    type="danger"
                    size="large"
                    block
                    onClick={() => {
                      dispatch(removeLicense({ id, idx }))
                    }}
                  >
                    <FiTrash />
                  </Button>
                </Col>
              </Row>
            )
          } else {
            return null
          }
        })}
        <br />
        <Row gutter={16}>
          <Col md={16}>
            <Button
              size="large"
              type="ghost"
              block
              onClick={() => {
                dispatch(addLicense(id))
              }}
            >
              Adicionar licença
            </Button>
          </Col>
          <Col md={8}>
            <Button size="large" type="primary" block onClick={() => setVisible(false)}>
              Salvar
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Container>
        <img src={image} alt="Atlas Plugins - Plugin" />
        <div>
          <h5>{name}</h5>
          <a
            href="#license-settings"
            onClick={(e) => {
              e.preventDefault()
              setVisible(true)
            }}
          >
            configurar licença
          </a>
        </div>
        <button
          onClick={() => {
            dispatch(removeProduct(id))
          }}
        >
          <FiTrash />
        </button>
      </Container>
    </React.Fragment>
  )
}

export default CartItem
