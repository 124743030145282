/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import { isEmail } from 'validator'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

function Settings() {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)

  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 10 },
  }
  const tailLayout = {
    wrapperCol: { offset: 2, span: 10 },
  }

  async function getData() {
    setLoading(true)

    try {
      const result = await api.get('/user/me')

      form.setFieldsValue({
        username: result.data.username,
        email: result.data.email,
        discord: result.data.discord,
      })
    } catch (err) {
      message.error('Não foi possível buscar suas informações no momento')
    }

    setLoading(false)
  }

  async function submit(values) {
    values.username = undefined

    if (!values.email) return message.info('Informe um e-mail')
    if (!isEmail(values.email)) return message.warning('Informe um e-mail válido')
    if (!values.password) return message.warning('Informe uma senha válida')

    setLoading(true)

    try {
      await api.patch('/user', values)

      message.success('Dados atualizados!')
    } catch (err) {
      message.error(err.response.data.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <AppLayout page="settings" title="Minhas compras">
      <h5>Configurações da conta</h5>
      <br />
      <Form {...layout} name="open-ticket" form={form} onFinish={submit}>
        <Form.Item label="Usuário" name="username">
          <Input size="large" disabled readOnly />
        </Form.Item>
        <Form.Item label="E-mail" name="email">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Discord" name="discord">
          <Input size="large" />
        </Form.Item>
        <Form.Item label="Nova senha" name="password">
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" size="large" htmlType="submit" disabled={loading} loading={loading}>
            Atualizar
          </Button>
        </Form.Item>
      </Form>
    </AppLayout>
  )
}

export default Settings
