import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  ${media.lessThan('medium')`
    flex-direction: column;
    margin-bottom: 0;
  `}

  ${media.between('medium', 'large')`
    flex-direction: column;
    margin-bottom: 0;
  `}

  ${media.greaterThan('large')`
    flex-direction: row;
    margin-bottom: 20px;
  `}
`

export const Logo = styled.img.attrs({
  src: require('../../assets/images/logo.png'),
  alt: 'Atlas Plugins - Logo',
})`
  width: 60px;
  margin-right: 30px;

  ${media.lessThan('medium')`
    margin-right: auto!important;
  `}

  ${media.between('medium', 'large')`
    margin-right: auto!important;
  `}

  ${media.greaterThan('large')`
    margin-right: 40px;
  `}
`

export const Links = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-right: auto;

  li {
    margin: 0 20px;

    a {
      color: var(--muted);
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }

    &.actived {
      a {
        color: var(--primary);
      }
    }

    &.youtube-channel {
      padding: 10px 15px;
      background: #ff0000;
      font-weight: 600;
      margin-right: 0;
      border-radius: 8px;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        margin-right: 5px;
      }

      a {
        color: #fff;
      }
    }

    &.account {
      padding: 10px 15px;
      background: var(--secondary);
      font-weight: 600;
      margin-right: 0;
      border-radius: 8px;

      a {
        color: #fff;
      }
    }
  }

  ${media.lessThan('medium')`
    align-items: center;
    justify-content: center;
    margin-right: 0;
    padding: 20px 0;

    .account {
      position: absolute;
      top: 5px;
      right: 0;
      padding: 5px 10px;
    }
  `}

  ${media.between('medium', 'large')`
    align-items: center;
    justify-content: center;
    margin-right: 0;
    padding: 20px 0;

    .account {
      position: absolute;
      top: 5px;
      right: 0;
      padding: 5px 10px;
    }
  `}

  ${media.greaterThan('large')`
    align-items: center;
  `}
`

export const Search = styled.div`
  position: relative;

  input {
    width: 400px;
    padding: 10px;
    padding-left: 40px;
    border-radius: 5px;
    border: none;
    background: rgba(0, 0, 0, 0.03);
  }

  .search-btn {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-55%);
    border: none;
    background: transparent;
    color: #000;
    font-size: 22px;
  }

  ul {
    position: absolute;
    background: #fff;
    top: 50px;
    left: 0;
    z-index: 999;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);

    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 10px;

      img {
        width: 28px;
        height: 28px;
        margin-right: 18px;
      }

      h5 {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span {
        font-size: 14px;
        color: var(--muted);
        margin-left: auto;
        margin-right: 15px;
      }

      button {
        padding: 5px 10px;
        border-radius: 3px;
        background: var(--primary);
        color: var(--white);
      }
    }
  }

  ${media.lessThan('medium')`
    width: 100%;

    input {
      width: 100%;
    }
  `}

  ${media.between('medium', 'large')`
    width: 100%;

    input {
      width: 100%;
    }
  `}

  ${media.greaterThan('large')`
    width: 400px;
  `}
`
