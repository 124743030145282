import React, { useState } from "react";
import { BsCodeSlash } from "react-icons/bs";
import { Modal } from "antd";
import { getToken } from "../../guard";
import slugify from "slugify";
import fileExtension from "../../helper/files";


function PluginAPI({ data }) {


    const [visible, setVisible] = useState(false);
    const download = function download(id, ext, title, version) {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
    
        let file = `https://api.atlasplugins.com/v1/plugin/${id}/api`;
    
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${getToken()}`);
    
        fetch(file, { headers })
          .then((response) => response.blob())
          .then((blobby) => {
            let objectUrl = window.URL.createObjectURL(blobby);
    
            anchor.href = objectUrl;
            anchor.download = `${slugify(`${title} API`)}.${ext}`;
            anchor.click();
    
            window.URL.revokeObjectURL(objectUrl);
    
            anchor.remove();
          });
      }
    return (
        <>
        <Modal
                title={data.title+" API"}
                visible={visible}
                onCancel={() => setVisible(false)}
                cancelText="Fechar"
                closable={false}
                okText="Baixar API"
                onOk={() =>
                    download(
                      data.id,
                      fileExtension.get(data.apiUrl),
                      data.title,
                      data.version
                    )
                  }
       
              >
                <h4>Como utilizar?</h4>
                <p>Este arquivo disponibilizado abaixo <b>APENAS SERVE PARA DESENVOLVEDORES</b>.</p>
                <p>Para utilizar a API deste plugin siga os passos abaixo:</p>
                <ul style={{ paddingLeft: "20px"}}>
                    <li><b>1º passo:</b>
                        <p>Baixe o arquivo clicando no botão abaixo</p>
                        
                    </li>
                    <li>
                        <b>2º passo:</b>
                        <p>Importe a API e o AtlasLicense.jar na Build Path da sua IDE. <a target="_blank" rel="noopener noreferrer" href="https://image.prntscr.com/image/dQTxdCJkSz2GZpEwrb2byA.png">(Imagem)</a></p>
                    </li>
                    <li>
                        <b>3º passo (IMPORTANTE):</b>
                        <p><b>NÃO</b> colocar o plugin da API(download abaixo) na pasta plugins.</p>
                    </li>
                    
                </ul>
                <br/>
                <p><b>Pronto!</b>, suas dependências de APIs dos nossos plugins estão configuradas.</p>
                <p>Cada plugin tem sua classe e seus métodos de API, caso não consiga encontrá-los decompilando as packages favor abrir um ticket <a href="/#/dashboard/suporte">clicando aqui.</a></p>
                <br/>
               
              </Modal>
        <a 
        style={{ marginLeft: "10px", fontSize: 22 }}
        onClick={(e) => {
            e.preventDefault()
            setVisible(true)
          }}
          href="!#">
                          <BsCodeSlash />
        </a>
        </>
    );
}

export default PluginAPI;