export default {
  status: (status) => {
    switch (status) {
      case "CREATED":
        return "Aguardando dados";
      case "PENDING":
        return "Em análise";
      case "APPROVED":
        return "Publicado";
      case "SUSPENDED":
        return "Recusado";
      default:
        return "Não binário";
    }
  },
};
