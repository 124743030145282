/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Button, message, Upload } from 'antd'
import { Spinner } from 'reactstrap'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'
import { getToken } from '../../guard'
import serverHelper from '../../helper/server'
import { FloatingDiv, ServerUploader } from './styles.js'
import { PlusOutlined } from '@ant-design/icons'

function Server() {
  const [form] = Form.useForm()

  const [imageUrl, setImageUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [me, setMe] = useState([])

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  }
  const tailLayout = {
    wrapperCol: { offset: 6, span: 18 },
  }

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const { data: dataMe } = await api.get('/user/me')

      setMe(dataMe)
      if (dataMe.server != null && dataMe.server.image != null) {
        setImageUrl('https://api.atlasplugins.com/v1/file/image/' + dataMe.server.image)
      }

      form.setFieldsValue(dataMe.server)
    } catch (err) {
      message.error('Não foi possível buscar os dados')
    }

    setLoading(false)
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('Por favor selecione apenas arquivos PNG ou JPG')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('A imagem precisa ser menor do que 2MB')
    }
    return isJpgOrPng && isLt2M
  }

  async function create() {
    setLoading(true)

    try {
      await api.post('/user/server')
      await getData()

      message.success('Pronto! Agora configure seu servidor')
    } catch (err) {
      message.error('Não foi possível buscar os dados')
    }

    setLoading(false)
  }

  async function submitRemove(values) {
    setUpdateLoading(true)
    try {
      await api.patch('/user/server/remove', values)

      message.success('Servidor removido com sucesso.')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (err) {
      message.error('Não foi possível buscar os dados')
    }
  }

  async function submit(values) {
    setUpdateLoading(true)

    try {
      await api.patch('/user/server', values)

      message.success('Dados sobre o servidor foram atualizados e enviadas para análise')
      setUpdateLoading(false)
    } catch (err) {
      message.error('Não foi possível buscar os dados')
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  return (
    <AppLayout page="server" title="Meu servidor">
      <h5 className="mb-4">Meu servidor</h5>
      <br />
      {loading ? (
        <Spinner color="primary" />
      ) : me.patrimony >= 200 ? (
        me.server ? (
          <div>
            <Row gutter={25}>
              <Col span={24} md={12}>
                <Form {...layout} name="update-server" form={form} onFinish={submit}>
                  <Form.Item
                    label="Nome do Servidor"
                    rules={[{ required: true, message: 'Informe o nome do servidor' }]}
                    name="name"
                  >
                    <Input size="large" />
                  </Form.Item>
                  <Form.Item
                    label="Descrição"
                    rules={[{ required: true, message: 'Informe a descrição' }]}
                    name="description"
                  >
                    <Input.TextArea rows={6} size="large" />
                  </Form.Item>
                  <Form.Item
                    label="IP do servidor"
                    rules={[{ required: true, message: 'Informe o IP do servidor' }]}
                    name="ip"
                  >
                    <Input size="large" />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      disabled={updateLoading}
                      loading={updateLoading}
                    >
                      {me.server.status === 'PENDING' || me.server.status === 'APPROVED'
                        ? 'Atualizar informações'
                        : me.server.status === 'CREATED'
                        ? 'Enviar informações do servidor para avaliação'
                        : 'Reenviar informações do servidor para avaliação'}
                    </Button>

                    {me.server.status === 'APPROVED' && (
                      <>
                        <br />
                        <br />
                        <Button
                          type={me.server.status === 'APPROVED' ? 'danger' : 'primary'}
                          size="large"
                          onClick={submitRemove}
                          disabled={updateLoading}
                          loading={updateLoading}
                        >
                          {me.server.status === 'PENDING'
                            ? 'Atualizar informações'
                            : me.server.status === 'APPROVED'
                            ? 'Remover servidor da página inicial'
                            : me.server.status === 'CREATED'
                            ? 'Enviar informações do servidor para avaliação'
                            : 'Reenviar informações do servidor para avaliação'}
                        </Button>
                      </>
                    )}
                  </Form.Item>
                </Form>
              </Col>
              <Col span={24} md={12}>
                <Upload
                  name="image"
                  listType="avatar"
                  showUploadList={false}
                  onChange={(info) => {
                    if (info.file.status === 'done') {
                      // Get this url from response in real world.
                      getBase64(info.file.originFileObj, (image) => {
                        setImageUrl(image)
                      })
                    }
                  }}
                  method="PATCH"
                  action={`https://api.atlasplugins.com/v1/user/server/image`}
                  beforeUpload={beforeUpload}
                  headers={{
                    authorization: `Bearer ${getToken()}`,
                  }}
                >
                  {imageUrl ? (
                    <ServerUploader>
                      <img src={imageUrl} alt="" className="avatar" />
                    </ServerUploader>
                  ) : (
                    <ServerUploader>
                      <PlusOutlined
                        style={{
                          fontSize: '50px',
                          color: '#999',
                        }}
                      />
                    </ServerUploader>
                  )}
                </Upload>

                <br />

                <h5 className="text-muted font-weight-light" style={{ marginTop: '20px' }}>
                  Situação: <b style={{ fontWeight: 'bold' }}>{serverHelper.status(me.server.status)}</b>
                </h5>
              </Col>
            </Row>
          </div>
        ) : (
          <Row gutter={25}>
            <Col span={24} md={12}>
              <FloatingDiv>
                <img
                  src="https://www.minecraft.net/etc.clientlibs/minecraft/clientlibs/main/resources/img/servers/servers-hub-3.png"
                  alt="Atlas Plugins - Sem servidor"
                  width="350"
                />
              </FloatingDiv>
            </Col>
            <Col span={24} md={12}>
              <h3>Como funciona?</h3>
              <p>
                <b>Wow! Incrível,</b> se você está vendo esta pagina quer dizer que você é um dos <br />
                <b>TOP COMPRADORES</b> de nosso site então você agora consegue publicar seu
                <br /> servidor na página inicial do nosso site podendo assim divulgar e engajar mais pessoas para jogar
                em seu servidor!
              </p>
              <button className="btn mt-4 mb-4" style={{ backgroundColor: '#0192d4', color: '#FFFF' }} onClick={create}>
                Publicar meu servidor
              </button>
            </Col>
          </Row>
        )
      ) : (
        <div className="text-center">
          <img src={require('../../assets/images/no-server.svg')} alt="Atlas Plugins - Sem servidor" width="250" />
          <div>
            <h4 className="text-muted mt-3">
              Você precisa ser um Top Comprador <br />
              para poder publicar seu servidor
            </h4>
          </div>
        </div>
      )}
    </AppLayout>
  )
}

export default Server
