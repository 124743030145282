import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 30px;
  background: var(--white);
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 20px #0000000b;

  input {
    width: 100%;
    margin-bottom: 5px;
    padding: 15px 0;
    border-bottom: solid 2px transparent;
    transition: all ease .3s;

    &:focus {
      border-bottom: solid 2px var(--primary);
    }
  }

  button {
    padding: 10px 25px;
    background: var(--secondary);
    color: var(--white);
    font-weight: 600;
    margin-top: 15px;
    border-radius: 8px;
  }
`
