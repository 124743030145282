import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--primary);
  margin-top: -250px;

  ${media.lessThan("medium")`
    flex-direction: column;
    padding: 20px 25px;
    justify-content: flex-start;
    align-items: flex-start;
  `}

  ${media.between("medium", "large")`
    flex-direction: column;
    padding: 20px 25px;
    justify-content: flex-start;
    align-items: flex-start;
  `}

  ${media.greaterThan("large")`
    flex-direction: row;
    padding: 20px 30px;
  `}
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 40px;

  img {
    max-width: 40px;
    margin-right: 15px;
  }

  h1 {
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    margin: 0;
  }

  ${media.lessThan("medium")`
    margin-bottom: 15px;
  `}

  ${media.between("medium", "large")`
    margin-bottom: 15px;
  `}
`;

export const Welcome = styled.div`
  h2 {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
  }
`;

export const Links = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-left: auto;
  list-style: none;

  li {
    margin-left: 20px;

    a {
      color: rgba(255, 255, 255, 0.6);
      font-weight: 600;
      font-size: 14px;
      transition: all ease 0.3s;

      &:hover {
        color: #fff;
      }
    }
  }

  ${media.lessThan("medium")`
    margin-top: 15px;
    justify-content: center;
    align-items: center;
  `}

  ${media.between("medium", "large")`
    margin-top: 15px;
    justify-content: center;
    align-items: center;
  `}
`;
