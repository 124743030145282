import React from "react";
import { Helmet } from "react-helmet";

import { Styles } from "../../components";
import { Page, Container, Content } from "./styles";

import AppHeader from "../../components/AppHeader";
import AppMenu from "../../components/AppMenu";

function AppLayout({ children, page, title }) {
  return (
    <Page>
      <Styles />
      <Helmet>
        <title>{title} - Atlas Plugins</title>
      </Helmet>
      <div style={{background: 'var(--primary)', height: 250}} />
      <AppHeader />
      <Container className="pt-0">
        <AppMenu page={page} />
        <Content>{children}</Content>
      </Container>
    </Page>
  );
}

export default AppLayout;
