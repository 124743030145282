import styled from 'styled-components'
import media from 'styled-media-query'
import { motion } from "framer-motion" // not "framer-motion/dist/framer-motion"

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96]
}

const backVariants = {
  exit: { x: -100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } }
}

export const Grid = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Col = styled(motion.div).attrs({
  initial: 'exit',
  animate: 'enter',
  exit: 'exit'
})`
  width: 520px;
  margin: 0 15px;

  ${({ right }) =>
    right &&
    `
    
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }

    img {
      max-width: 90px;
    }

    width: 580px;

    h1 {
      margin: 65px 0 20px 0;
      font-size: 54px;
      line-height: 56px;
      font-weight: 500;
      color: #fff;
      text-align: right;
    }

    p {
      color: rgba(255, 255, 255, .6);
      text-align: right;
      margin: 0 0 25px 0;
    }

    a {
      color: #fff;
      font-weight: 800;
      text-decoration: none;
    }
  `}
`

export const Card = styled(motion.div).attrs({
  variants: backVariants
})`
  background: #fff;
  border-radius: 5px;
  padding: 60px;
  box-shadow: 0 70px 45px -40px rgba(0, 0, 0, 0.08),
    0 10px 30px -5px rgba(0, 0, 0, 0.1);

  a {
    text-decoration: none;
    color: #000;
  }

  .terms-and-conditions {
    display: flex;
    align-items: baseline;
    gap: 6px;
    margin: 10px 0px;
    color: #777777;

    a {
      display: contents;
      color: #007bff;
    }
  }
`

export const Page = styled(motion.div).attrs({
  initial: 'initial',
  animate: 'enter',
  exit: 'exit'
})`
  min-height: 100vh;
  background: var(--primary);

  * {
    border: none;
  }

  ${media.lessThan('medium')`
    ${Grid} {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    ${Col} {
      width: 100%;
      padding: 30px;
      margin: 0;

      div {
        align-items: center;
        justify-content: center;

        p {
          display: none;
        }

        a {
          display: none;
        }
      }

      img {
        max-height: 35px;
        margin: 0 auto!important;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 28px;
        line-height: 30px;
        font-weight: 900;
        margin-top: 20px;
        text-align: center;
      }
    }

    ${Card} {
      max-width: 100%;
      padding: 30px;

      input {
        padding: 15px;
      }
    }
  `}
`
