import jwt_decode from 'jwt-decode'

const AUTH_SESSION = 'Atlas@Token'
const USER_SESSION = 'Atlas@User'

export function setToken(token) {
  return localStorage.setItem(AUTH_SESSION, token)
}

export function setUser(data) {
  return localStorage.setItem(USER_SESSION, JSON.stringify(data))
}

export function getToken() {
  return localStorage.getItem(AUTH_SESSION)
}

export function getUser() {
  return JSON.parse(localStorage.getItem(USER_SESSION))
}

export function isLogged() {
  if (localStorage.getItem(AUTH_SESSION) !== null) {
    const decodedToken = jwt_decode(localStorage.getItem(AUTH_SESSION))
    return !(decodedToken.exp < new Date().getTime() / 1000)
  }
  return false
}

export function logout() {
  localStorage.removeItem(USER_SESSION)

  return localStorage.removeItem(AUTH_SESSION)
}
