import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`

export const Category = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  background: ${({ actived }) => (actived ? 'var(--primary)' : '#fff')};
  border-radius: 20px;
  padding: 30px 22px;
  -webkit-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  z-index: 99;
  margin: 10px;
  cursor: pointer;

  i {
    font-size: 68px;
    color: ${({ actived }) => (actived ? 'var(--white)' : 'var(--secondary)')};
  }
  svg {
    font-size: 68px;
    color: ${({ actived }) => (actived ? 'var(--white)' : 'var(--secondary)')};
  }
`

export const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ actived }) => (actived ? 'var(--white)' : 'var(--muted)')};
`
