import styled from 'styled-components';
import {
  Dropdown as BDropdown,
  DropdownToggle as BDropdownToggle,
  DropdownMenu as BDropdownMenu,
  DropdownItem as BDropdownItem,
} from 'reactstrap';
import media from 'styled-media-query';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  ${media.lessThan('medium')`
    align-items: flex-start;
    flex-direction: column;
  `}

  ${media.between('medium', 'large')`
    align-items: flex-start;
    flex-direction: column;
  `}
`;

export const Title = styled.h3``;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Dropdown = styled(BDropdown)`
  margin-left: 20px;
`;

export const DropdownToggle = styled(BDropdownToggle)`
  border-radius: 8px;
  background: ${({ star }) => (star ? 'var(--primary)' : '#e9e9e9')};
  color: ${({ star }) => (star ? 'var(--white)' : '#828282')};
  border: none;
  box-shadow: none !important;
  transition: all ease 0.3s;

  &:hover,
  &:focus {
    background: ${({ star }) =>
      star ? 'var(--primary)' : 'rgba(0, 0, 0, .3)'};
  }

  svg {
    margin-left: 10px;
  }

  ${media.lessThan('medium')`
    font-size: 14px;
  `}

  ${media.between('medium', 'large')`
    font-size: 14px;
  `}
`;

export const DropdownMenu = styled(BDropdownMenu)`
  border: none;
  -webkit-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
`;

export const DropdownItem = styled(BDropdownItem)``;
