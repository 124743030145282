import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { FaDiscord } from "react-icons/fa";
import ReCAPTCHA from "react-recaptcha";

import DefaultLayout from "../../layouts/DefaultLayout";
import { Container, DiscordCard } from "./styles";

import api from "../../services/api";

function Order() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("")

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 12 },
  };

  async function submit(data) {
    if (!response) return message.info("Captcha não resolvido");

    setLoading(true);

    data = { ...data, response };

    try {
      await api.post("/demand", data);

      form.resetFields();
      message.success(
        "Encomenda enviada! Aguarde, entraremos em contato pelo Discord"
      );
    } catch (err) {
      message.error("Não foi possível enviar seu pedido agora");
    }

    setLoading(false);
  }

  return (
    <DefaultLayout title="Encomendar plugin" pageUrl="/encomendar">
      <Container>
        <br />
        <h1 className="mb-0">Encomendar</h1>
        <h4 className="text-muted">Faça uma encomenda do seu jeito!</h4>
        <br />
        <br />
        <Form {...layout} name="open-ticket" form={form} onFinish={submit}>
          <Form.Item
            label="Nome do Plugin"
            name="pluginName"
            rules={[{ required: true, message: "Informe o nome do plugin" }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Seu discord"
            name="discord"
            rules={[{ required: true, message: "Informe o discord" }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Detalhes"
            name="description"
            rules={[{ required: true, message: "Informe a mensagem" }]}
          >
            <Input.TextArea rows={8} size="large" />
          </Form.Item>
          <Form.Item></Form.Item>
          <Form.Item {...tailLayout}>
            <main
              style={{
                marginTop: -50,
                marginBottom: 15,
                height: "78px",
                position: "relative",
              }}
            >
              <ReCAPTCHA
                sitekey="6LdearEZAAAAAKktAO99pbj3B2ZN2aeScIlWoXhU"
                size="normal"
                render="explicit"
                verifyCallback={setResponse}
                onloadCallback={() => console.log('reCaptcha callback')}
              />
            </main>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Enviar pedido
            </Button>
          </Form.Item>
        </Form>
        <br />
        <DiscordCard>
          <FaDiscord />
          <div>
            <h3>Faça seu pedido pelo Discord</h3>
            <p>
              Para conseguir um atendimento mais rápido, recomendamos que faça
              seu pedido através da nossa comunidade do Discord.
            </p>
            <button
              onClick={() => window.open("https://discord.gg/J7MUSpC", "blank")}
            >
              Entrar na Comunidade
            </button>
          </div>
        </DiscordCard>
        <br />
      </Container>
    </DefaultLayout>
  );
}

export default Order;
