import styled from 'styled-components'
import { motion } from 'framer-motion'
import media from 'styled-media-query'

import { Container as Button } from '../../components/Button/styles'

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
}

const backVariants = {
  exit: { x: 100, opacity: 0, transition },
  enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
}

export const Grid = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Col = styled(motion.div).attrs({
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
})`
  width: 520px;
  margin: 0 15px;

  ${({ left }) =>
    left &&
    `
    img {
      max-width: 90px;
    }

    width: 580px;

    h1 {
      margin-top: 65px;
      font-size: 58px;
      line-height: 60px;
      font-weight: 500;
      color: #fff;
    }
  `}
`

export const Card = styled(motion.form).attrs({
  variants: backVariants,
})`
  background: #fff;
  border-radius: 5px;
  padding: 60px;
  box-shadow: 0 70px 45px -40px rgba(0, 0, 0, 0.08), 0 10px 30px -5px rgba(0, 0, 0, 0.1);

  a {
    text-decoration: none;
    color: #000;
  }
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -20px;

  ${Button} {
    width: 49% !important;
  }
`

export const Divider = styled.div`
  margin: 20px 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  height: 2px;
  border-radius: 2px;
`

export const Page = styled(motion.div).attrs({
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  layoutId: 'menuOverlay',
})`
  min-height: 100vh;
  background: var(--primary);

  * {
    border: none;
  }

  ${media.lessThan('medium')`
    ${Grid} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    ${Col} {
      width: 100%;
      padding: 30px;
      margin: 0;

      img {
        max-height: 35px;
        margin: 0 auto!important;
        margin-bottom: 10px;
      }

      h1 {
        font-size: 28px;
        line-height: 30px;
        font-weight: 900;
        margin-top: 20px;
        text-align: center;
      }
    }

    ${Card} {
      max-width: 100%;
      padding: 30px;

      input {
        padding: 15px;
      }
    }

    ${Flex} {
      flex-direction: column;
      align-items: stretch;

      p {
        font-size: 12px;
      }

      ${Button} {
        margin-bottom: 0;
        width: 100% !important;
      }
    }
  `}
`
