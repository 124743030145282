import React from "react";

import { Container } from "./styles";

function AboutSection() {
  return (
    <Container>
      <h5>Sobre a nossa nova equipe ATLAS</h5>
      <p>
      Sob nova administração, a equipe Atlas está empenhada em oferecer um atendimento excepcional aos nossos clientes.
      Nosso foco é alcançar a melhor qualidade do mercado em plugins para Minecraft. 
      Estamos continuamente aprimorando nossas tecnologias para garantir que nossos futuros lançamentos sejam inovadores e impressionantes.
      Para assegurar a proteção dos seus plugins, contamos com um sistema robusto contra roubo, permitindo que você desative seus plugins sempre que desejar. 
      Se você já possui plugins da Atlas, fique tranquilo: continuaremos oferecendo suporte contínuo e fornecendo atualizações regulares para garantir o melhor desempenho e segurança.
      </p>
    </Container>
  );
}

export default AboutSection;
