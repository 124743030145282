import React, { useEffect, useState } from "react";
import { Table, Tag, message } from "antd";

import AppLayout from "../../layouts/AppLayout";

import api from "../../services/api";

function Purchases() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Pedido",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Forma de Pagamento",
      dataIndex: "gateway",
      key: "gateway",
      render: (text) => {
        switch (text) {
          case "PAYPAL":
            return "PayPal";
          case "MERCADOPAGO":
            return "Mercado Pago";
          default:
            return <Tag>Indefinido</Tag>;
        }
      },
    },
    {
      title: "Subtotal",
      dataIndex: "subtotal",
      key: "subtotal",
      render: (text) =>
        `R$${parseFloat(text).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Última atualização",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        switch (text) {
          case "PENDING":
            return <Tag color="geekblue">Pagamento pendente</Tag>;
          case "PAID":
            return <Tag color="green">Pago</Tag>;
          case "REFUNDED":
            return <Tag color="red">Estornado</Tag>;
          default:
            return <Tag>Indefinido</Tag>;
        }
      },
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);

    try {
      const result = await api.get("/user/invoices");

      setData(
        result.data.map((el) => {
          return {
            key: el.id,
            ...el,
          };
        })
      );
    } catch (err) {
      message.error("Ocorreu algum erro ao tentar buscar seus dados");
    }

    setLoading(false);
  }

  return (
    <AppLayout page="purchases" title="Minhas compras">
      <h5 className="mb-4">Minhas Compras</h5>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        locale={{ emptyText: "Você não efetuou nenhuma compra" }}
      />
    </AppLayout>
  );
}

export default Purchases;
