export default {
  convert: (patrimony) => {
    patrimony = parseFloat(patrimony);

    if (patrimony >= 1000) {
      return 'Extreme'
    } else if (patrimony >= 800) {
      return 'Ultra'
    } else if (patrimony >= 600) {
      return 'Hiper'
    } else if (patrimony >= 400) {
      return 'Mega'
    } else if ((patrimony >= 200)) {
      return 'Top'
    } else {
      return 'Iniciante'
    }
  }
}