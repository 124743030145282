import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import { Tag, Modal, message, Button, Timeline, Input } from 'antd'
import { FiSettings } from 'react-icons/fi'
import { AiFillYoutube } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import HTML2React from 'html2react'
import slugify from 'slugify'

import AppLayout from '../../layouts/AppLayout'
import ShimmerProduct from '../../components/ShimmerProduct'

import { Plugin } from './styles'

import api from '../../services/api'
import apiv2 from '../../services/apiv2'

import { getToken } from '../../guard'
import fileExtension from '../../helper/files'
import PluginInfo from '../../components/PluginInfo'
import PluginAPI from '../../components/PluginAPI'

const { Search } = Input

function Plugins() {
  const [loading, setLoading] = useState(true)
  const [loadingFullscale, setLoadingFullscale] = useState(false)
  const [ip, setIp] = useState('')
  const [port, setPort] = useState('')
  const [data, setData] = useState([])
  const [licenseVersions, setLicenseVersions] = useState([
    {
      version: '2.0.0',
      stage: 'RELEASE',
      downloadUrl: 'https://api.atlasplugins.com/v1/plugin/license',
      title: 'Primeira versão do AtlasLicense (v2.0.0)',
      description: 'A primeira versão do AtlasLicense',
    },
  ])
  const [token, setToken] = useState('Carregando...')
  const [tokenLoading, setTokenLoading] = useState(true)

  const [modalVisible, setModalVisible] = useState(false)
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)
  const [fullscaleEditVisible, setFullScaleEditVisible] = useState(false)
  const [loadingLicense, setLoadingLicense] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  function getLatestRelease() {
    const filteredReleases = licenseVersions.filter((release) => release.stage === 'RELEASE');
    if (filteredReleases.length === 0) {
      return "0.0.0";
    }
    const sortedReleases = filteredReleases.sort((a, b) => {
      if (a.version > b.version) {
        return -1;
      }
      if (a.version < b.version) {
        return 1;
      }
      return 0;
    });
    return sortedReleases[0];
  }

  async function submitFullScale() {
    if (ip.length === 0 || !ip.includes('.')) {
      message.error('Por favor forneça um endereço de IP válido.')
      return
    }
    if (port.length === 0) {
      message.error('Por favor forneça uma porta do servidor válida.')
      return
    }
    setLoadingFullscale(true)
    try {
      await api.patch(`/license/update/all`, { ip, port })
      message.success('Todas as suas licenças foram atualizadas com sucesso.')
      setLoadingFullscale(false)
    } catch (err) {
      message.error('Ops, ocorreu um erro ao tentar enviar seus dados para o servidor.')
    }
  }

  async function getData() {
    setLoading(true)

    try {
      const result = await api.get('/user/plugins')
      setData(result.data)

      const versions = await apiv2.get('/license/check')
      const data = versions.data
      if (data.length > 0) {
        setLicenseVersions(data)
      }

      const userToken = await apiv2.get('/token')
      const token = userToken.data.token
      setToken(token)
      setTokenLoading(false)
    } catch (err) {
      message.error('Ocorreu algum erro ao tentar buscar seus dados')
    }

    setLoading(false)
  }

  async function renewToken() {
    setToken('Carregando...')
    setTokenLoading(true)
    try {
      const result = await apiv2.post('/token/renew')
      const newToken = result.data.token
      setToken(newToken)
      setTokenLoading(false)
    } catch (err) {
      message.error('Ocorreu um erro durante a renovação do seu token de acesso')
    }
  }

  async function download(id, ext, title, version) {
    let anchor = document.createElement('a')
    document.body.appendChild(anchor)

    let file = `https://api.atlasplugins.com/v1/plugin/${id}/cloud`

    let headers = new Headers()
    headers.append('Authorization', `Bearer ${getToken()}`)

    fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = `${slugify(`${title} ${version}`)}.${ext}`
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)

        anchor.remove()
      })
  }

  async function downloadLicense() {
    setLoadingLicense(true)
    let anchor = document.createElement('a')
    document.body.appendChild(anchor)

    let file = `https://api.atlasplugins.com/v1/plugin/license`

    let headers = new Headers()
    headers.append('Authorization', `Bearer ${getToken()}`)

    fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby)

        anchor.href = objectUrl
        anchor.download = `AtlasLicense.jar`
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)

        anchor.remove()
        setLoadingLicense(false)
      })
    return
  }

  return (
    <AppLayout page="plugins" title="Meus plugins">
      <Modal
        title="Alterar IP das licenças em massa"
        visible={fullscaleEditVisible}
        onCancel={() => setFullScaleEditVisible(false)}
        cancelText="Fechar"
        closable={true}
        footer={[
          <>
            <Button type="primary" onClick={submitFullScale} loading={loadingFullscale}>
              Alterar
            </Button>
          </>,
        ]}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <h4>Alterar licenças em massa:</h4>
        <p>Utilize este formulário para alterar o IP de todas as suas licenças para o ip e porta preenchidos abaixo.</p>
        <br />
        <input placeholder="Informe o IP" value={ip} onChange={(e) => setIp(e.target.value)} />
        <input placeholder="Informe a porta" value={port} onChange={(e) => setPort(e.target.value)} />
      </Modal>
      <h5 className="mb-4">
        Meus plugins
        <button className="float-right btn btn-primary btn-sm" onClick={() => setFullScaleEditVisible(true)}>
          <FiSettings /> Alterar licenças em massa
        </button>
      </h5>
      <Row>
        {loading ? (
          <>
            <Col sm={6} md={3}>
              <ShimmerProduct />
            </Col>
            <Col sm={6} md={3}>
              <ShimmerProduct />
            </Col>
            <Col sm={6} md={3}>
              <ShimmerProduct />
            </Col>
            <Col sm={6} md={3}>
              <ShimmerProduct />
            </Col>
          </>
        ) : data.length === 0 ? (
          <Col md={12} className="text-center">
            <i className="far fa-grin-beam-sweat mb-4" style={{ fontSize: 72 }}></i>
            <h3 className="text-muted">
              Você ainda não possui <br />
              nenhum plugin
            </h3>
          </Col>
        ) : (
          <>
            <Col sm={6} md={3}>
              <Plugin launcher>
                <img src="https://i.imgur.com/uL4oNKI.png" alt={`AtlasPlugins`} />
                <div>
                  <p>LAUNCHER</p>
                  <h5>
                    AtlasLicense
                    <Tag color="#4cd62b" style={{ marginLeft: 5 }}>
                      ESTÁVEL
                    </Tag>
                  </h5>
                  <div className="pt-3">
                    <button onClick={() => setDownloadModalVisible(true)}>Baixar</button>
                    <button onClick={() => setModalVisible(true)}>
                      <i className="fas fa-question"></i>
                    </button>
                  </div>
                </div>
              </Plugin>
              <Modal
                title="AtlasLicense - Loader da Atlas Plugins"
                width="70%"
                visible={downloadModalVisible}
                onCancel={() => setDownloadModalVisible(false)}
                cancelText="Fechar"
                closable={true}
                footer={[
                  <>
                    <Button
                      type="danger"
                      style={{ flex: 1, alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}
                      onClick={() => window.open('https://www.youtube.com/watch?v=juNtVjPlhb8')}
                    >
                      <AiFillYoutube style={{ marginRight: '10px' }} /> Assistir tutorial
                    </Button>
                    <Button type="primary" onClick={() => downloadLicense()} loading={loadingLicense}>
                      Baixar (versão {getLatestRelease().version} estável)
                    </Button>
                  </>,
                ]}
                okButtonProps={{ style: { display: 'none' } }}
              >
                <div class="row">
                  <div class="col-md-6">
                    <h1>AtlasLicense</h1>
                    <p>
                      O plugin de carregamento e anti-cracker <b>mais seguro</b> já feito pela Atlas Plugins.
                    </p>
                    <h6>Por que utilizar?</h6>
                    <p>
                      Ao utilizar a versão mais nova do AtlasLicense você garante que seus plugins sempre estarão
                      seguros e livres de crack ou malware. Esta nova versão inclui algumas novas features de segurança
                      e um patch de anti-crack.
                    </p>
                    <br />
                    <h6>Possíveis problemas</h6>
                    <p>
                      Nós recomendamos o uso do Java 8 (versão jre1.8.0_231), esta versão é a versão que nós
                      desenvolvedores utilizamos para programar os plugins, vocês clientes utilizando esta versão do
                      Java garantem que o plugin funcionará conforme o esperado. Para baixar a versão 231{' '}
                      <a href="https://www.mediafire.com/file/h5v8zuj9urmwyum/jre-8u231-windows-x64.exe/file">
                        clique aqui
                      </a>
                    </p>
                    <br />
                    <h6>Retrocompatibilidade</h6>
                    <p>
                      Agora com a nova atualização do AtlasLicense todos os tipos de servidor (Spigot, Paperspigot e
                      servidores 1.9+) funcionam apenas com uma jar, facilitando assim o uso e não precisando selecionar
                      mais dentre as jars.
                    </p>
                    <br />
                    <h6>Seu token:</h6>
                    <p>
                      Utilize este token na config.yml do AtlasLicense.jar para que ele possa verificar a validade dos
                      seus plugins.
                    </p>
                    <Search
                      value={token}
                      size="large"
                      style={{ width: '83%' }}
                      enterButton="Renovar"
                      loading={tokenLoading}
                      onSearch={renewToken}
                    />
                  </div>
                  <br />
                  <div class="col-md-6">
                    <h6>Últimas atualizações:</h6>
                    <br />
                    <Timeline pending={true}>
                      {licenseVersions.map((el) => (
                        <Timeline.Item key={el.version}>
                          <h6 className="mb-0">
                            AtlasLicense <small className="text-muted">{el.version}</small>
                            <Tag color={el.stage == "RELEASE" ? "#4cd62b" : "#FF0000"} style={{ marginLeft: 5 }}>{el.stage == "RELEASE" ? "ESTÁVEL" : el.stage }</Tag>
                          </h6>
                          
                          <hr />
                          {HTML2React(el.description)}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                </div>
              </Modal>
              <Modal
                title="Sobre o AtlasLicense"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                cancelText="Fechar"
                closable={false}
                okButtonProps={{ style: { display: 'none' } }}
              >
                <h4>Como usar?</h4>
                <p>
                  Basta você remover <b>TODOS</b> os plugins antigos e colocar apenas o AtlasLicense.jar e apontar o IP
                  dos plugins que você quer que funcione naquela maquina aqui no nosso painel e Pronto!
                </p>
                <br />
                <h4>O que é?</h4>
                <p>
                  O AtlasLicense é um novo sistema criado por nós para evitar fraude e crack/quebra de licença de nossos
                  plugins, priorizando assim a integridade de nossos plugins e também fornecendo um conteúdo exclusivo
                  para nossos clientes que pagam por isso.
                </p>
                <br />
                <h4>O que mudou?</h4>
                <p>
                  <ul style={{ paddingLeft: '20px' }}>
                    <li> Agora os plugins atualizam-se sozinhos (100% funcional) </li>
                    <li>
                      {' '}
                      Um único plugin irá fazer as funções de todos os outros plugins apontados para o IP configurado em
                      nosso Painel de Controle.{' '}
                    </li>
                    <li>
                      {' '}
                      AtlasHelper integrado (Reload/Stop/Start de nossos plugins, Verificação de CPU, Verificação de uso
                      de Memória e etc).{' '}
                    </li>
                    <li>
                      {' '}
                      Anti-Crack 100% funcional (Por não existirem mais as jars dos nossos plugins, os autores de cracks
                      e afins não conseguirão ter acesso ao código para crackeá-los.{' '}
                    </li>
                    <li> Outras funções muito melhores para vocês!</li>
                  </ul>
                </p>
                <br />
                <p>
                  <b>Atenção</b>: Alguns plugins não são compatíveis com o AtlasLicense (na maioria das vezes são
                  plugins que rodam em bungeecord como por exemplo o AtlasPunish) por isso os mesmos não foram adaptados
                  para esse novo sistema de licenças e continuam funcionando normalmente apenas precisam ser
                  atualizados.
                </p>
              </Modal>
            </Col>
            {data.map((el) => (
              <Col sm={6} md={3} key={el.id}>
                <Plugin>
                  <img src={el.image} alt={`${el.title} - AtlasPlugins`} />
                  <div>
                    <p>{el.category.name}</p>
                    <h5>{el.title}</h5>
                    <span>versão {el.version}</span>
                    <div className="pt-3">
                      {el.download && (
                        <button onClick={() => download(el.id, fileExtension.get(el.file), el.title, el.version)}>
                          Baixar
                        </button>
                      )}
                      <PluginInfo data={el} />

                      <Link to={`/dashboard/plugins/${el.id}/licencas`} style={{ fontSize: 22 }}>
                        <FiSettings />
                      </Link>
                      {el.api && !el.download && <PluginAPI data={el} />}
                    </div>
                  </div>
                </Plugin>
              </Col>
            ))}
          </>
        )}
      </Row>
    </AppLayout>
  )
}

export default Plugins
