/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table, Modal, Select, message } from "antd";
import { Row, Col } from "reactstrap";

import AppLayout from "../../layouts/AppLayout";
import License from "../../components/License";
import ShimmerProduct from "../../components/ShimmerProduct";

import { AddLicense } from "./styles";

import api from "../../services/api";

function PluginsLicenses({ match }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [history, setHistory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [gateway, setGateway] = useState("MERCADOPAGO");

  const columns = [
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Porta",
      dataIndex: "port",
      key: "port",
    },
    {
      title: "Data de Conexão",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString("pt-BR"),
    },
  ];

  async function getLicenses() {
    setLoading(true);

    try {
      const result = await api.get(`/user/plugin/${match.params.id}/licenses`);

      setData(result.data);

      var histories = [];

      result.data.forEach((license) => {
        license.history.forEach((item) => {
          histories.push(item);
        });
      });

      histories.sort(function (a, b) {
        if (a < b) {
          return 1;
        }
        if (a > b) {
          return -1;
        }
        return 0;
      });

      setHistory(histories);
    } catch (err) {
      message.error("Não foi possível obter as licenças");
    }

    setLoading(false);
  }

  async function pay() {
    setPayLoading(true);

    try {
      const { data: { link } } = await api.post(`/checkout/pay/${gateway}`, {
        products: [{
          id: data[0].pluginId,
          name: data[0].pluginName,
          price: data[0].plugin.price,
          licenses: [{
            ip: '0.0.0.0',
            port: '25565'
          }]
        }],
        cupom: "",
        isLicense: true
      });

      window.location.href = link
    } catch (err) {
      message.error(err.response.data.message);
    }

    setPayLoading(false);
  }

  useEffect(() => {
    getLicenses();
  }, [match]);

  return (
    <AppLayout page="plugins" title="Meus plugins">
      <h5 className="mb-4">Configuração da Licença</h5>
      <Modal
        title="Adicionar licença"
        visible={visible}
        okText="Pagar R$5,00"
        cancelText="Cancelar compra"
        okButtonProps={{loading: payLoading}}
        onCancel={() => setVisible(false)}
        onOk={pay}
      >
        <p>
          <b>O que é uma licença adicional?</b> <br />
          Uma licença adicional é um desconto que nós da Atlas Plugins
          disponibilizamos para não prejudicar vocês que querem utilizar este
          mesmo plugin em vários servidores, ao comprar a licença adicional
          deste plugin você pagará apenas <b>R$5,00</b> pois você já comprou
          este plugin em seu valor inteiro.
        </p>
        <p>
          <b>Atenção:</b> Licenças adicionais apenas podem ser utilizadas no
          mesmo endereço de IP da licença original , trocando assim apenas a
          porta do servidor portanto verifique com a sua hospedagem se você está
          hospedado no mesmo dedicado e com o mesmo endereço de IP
        </p>
        <br />
        <h5>Selecione a forma de pagamento:</h5>
        <Select value={gateway} onChange={setGateway} style={{width: '100%'}}>
          <Select.Option value="MERCADOPAGO">MercadoPago</Select.Option>
          <Select.Option value="PAYPAL">PayPal</Select.Option>
        </Select>
      </Modal>
      <Row>
        {loading ? (
          <>
            <Col sm={6} md={3}>
              <ShimmerProduct />
            </Col>
            <Col sm={6} md={3}>
              <ShimmerProduct />
            </Col>
            <Col sm={6} md={3}>
              <ShimmerProduct />
            </Col>
          </>
        ) : (
          data.map((el) => (
            <Col sm={6} md={3} key={el.id}>
              <License data={el} />
            </Col>
          ))
        )}
        <Col sm={6} md={3}>
          <AddLicense onClick={() => setVisible(true)}>
            Adicionar licença
          </AddLicense>
        </Col>
      </Row>
      <br />
      <h6>Histórico de conexão</h6>
      <Table
        dataSource={history}
        columns={columns}
        loading={loading}
        locale={{ emptyText: "Não há logs de conexão" }}
      />
    </AppLayout>
  );
}

export default PluginsLicenses;
