/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import { message } from 'antd'
import Fade from 'react-reveal/Fade'
import PropagateLoader from 'react-spinners/PropagateLoader'

import { AiFillHome, AiFillYoutube } from 'react-icons/ai'

import { Container, Logo, Links, Search } from './styles'

import api from '../../services/api'
import SearchItem from '../SearchItem'

function Menu() {
  const history = useHistory()

  const [search, setSearch] = useState('')
  const [searchLoading, setSearchLoading] = useState(true)
  const [data, setData] = useState([])

  async function listPlugins() {
    if (search.length > 3) {
      try {
        const result = await api.get(`/plugin/search?q=${encodeURIComponent(search)}`)
        setData(result.data)
      } catch (err) {
        message.error(err?.response?.data?.message)
      }
      setSearchLoading(false)
    }
  }

  function useDebounce(callback, timeout, deps) {
    const timeoutId = useRef()

    useEffect(() => {
      clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(callback, timeout)

      return () => clearTimeout(timeoutId.current)
    }, deps)
  }

  useDebounce(listPlugins, 1000, [search])

  let { id } = useParams()

  async function loadParams() {
    if (id) {
      try {
        const result = await api.get(`/plugin/search?q=${encodeURIComponent(id)}`)
        setData(result.data)
        setSearchLoading(false)
      } catch (err) {
        message.error(err.response.data.message)
      }
    }
  }

  useEffect(() => {
    loadParams()
  }, [])

  return (
    <Container>
      <Logo onClick={() => history.push('/')} />
      <Links>
        <li>
          <Link to="/">
            <AiFillHome style={{ marginBottom: '4px' }} /> Início
          </Link>
        </li>
        <li className="youtube-channel">
          <a
            href="https://youtube.com/c/AtlasPlugins"
            target="_blank"
            rel="noreferrer noopener"
            alt="Atlas's Youtube Channel"
          >
            <AiFillYoutube />
            Canal no Youtube
          </a>
        </li>
      </Links>
      <Search>
        <input
          placeholder="Procurando por algum plugin específico?"
          value={search}
          onChange={(e) => {
            setSearchLoading(true)
            setSearch(e.target.value)
          }}
        />
        <button className="search-btn">
          <FiSearch />
        </button>
        {(search.length > 3 || id) && (
          <Fade bottom cascade>
            <ul>
              {searchLoading ? (
                <li style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <PropagateLoader size={5} color={'#0192d4'} />
                </li>
              ) : data.length > 0 ? (
                data.map((el) => (
                  <SearchItem
                    data={el}
                    onDetailsClick={() => {
                      setSearch('')
                    }}
                  />
                ))
              ) : (
                <li>Não encontramos resultado para "{search}"</li>
              )}
            </ul>
          </Fade>
        )}
      </Search>
      <Links className="mr-0 ml-auto">
        <li className="account">
          <Link to="/login">Minha conta</Link>
        </li>
      </Links>
    </Container>
  )
}

export default Menu
