import styled, { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }

  html, body, #app {
    height: 100%;
  }

  *, a, button, input {
    border: 0;
    outline: 0;
    font-family: 'Montserrat', sans-serif;

    &:focus {
      outline: none; 
      box-shadow: none!important;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    color: #2d3748;
  }

  :root {
    --primary: #007BFF;
    --secondary: #FB6D37;
    --tertiary: rgb(32,34,37);
    --quaternary: #D6EFFA;
    --quinary: #393d42;
    --senary: #828386;
    --muted: #747272;

    --white: #fff;
    --gray: #8a8c90;
    --chat-input: rgb(64,68,75);
    --symbol: #74777a;
    --link: #5d80d6;
  }
`;

export const Card = styled.div`
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background: var(--white);
  z-index: 99;
  box-shadow: 0px 3px 20px #0000000b;
`;
