import styled from 'styled-components'

export const Container = styled.div``

export const Card = styled.div`
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background: var(--white);
  z-index: 99;
  box-shadow: 0px 3px 20px #0000000b;
`
