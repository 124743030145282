import React, { useEffect, useState } from 'react'

import { Row, Col } from 'reactstrap'
import { GiPodiumWinner } from 'react-icons/gi'
import { FiShoppingBag } from 'react-icons/fi'
import { ImTicket } from 'react-icons/im'

import { Table, Tag, message } from 'antd'

import AppLayout from '../../layouts/AppLayout'
import { Container, Card } from './styles'

import api from '../../services/api'

export default function Promotion() {
  const [loading, setLoading] = useState(true)
  const [tickets, setTickets] = useState(0)
  const [promotionalInvoices, setPromotionalInvoices] = useState(0)
  const [winner, setWinner] = useState('Ainda não sorteado')
  const [data, setData] = useState([])

  const columns = [
    {
      title: 'Pedido',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Forma de Pagamento',
      dataIndex: 'gateway',
      key: 'gateway',
      responsive: ['xxl', 'xl', 'lg'],
      render: (text) => {
        switch (text) {
          case 'PAYPAL':
            return 'PayPal'
          case 'MERCADOPAGO':
            return 'Mercado Pago'
          default:
            return <Tag>Indefinido</Tag>
        }
      },
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (text) =>
        `R$${parseFloat(text).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['xxl', 'xl', 'lg'],
      render: (text) => new Date(text).toLocaleString('pt-BR'),
    },
    {
      title: 'Total de tickets recebidos',
      dataIndex: 'subtotal',
      key: 'subtotal',
      align: 'center',
      render: (text) => (
        <Tag color="green" style={{ fontSize: '15px' }}>
          + {Math.floor(text)} tickets
        </Tag>
      ),
    },
  ]

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const result = await api.get('/user/promotion')
      const data = result.data
      setData(data.lastInvoices)
      setPromotionalInvoices(data.promotionalInvoices)
      setTickets(data.userTickets)
      setWinner(data.winner)
    } catch (err) {
      message.error('Ocorreu algum erro ao tentar buscar seus dados')
    }
    setLoading(false)
  }

  return (
    <AppLayout page="promotion" title="Promoção Atlas Plugins">
      <Container>
        <h5 className="mb-4">Meus tickets promocionais</h5>
        <Row>
          <Col md={4}>
            <Card className="mb-4">
              <Row>
                <Col md={1} style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-grid' }}>
                  <ImTicket size={40} />
                </Col>
                <Col md={6}>
                  <h1 className="mb-0">{tickets}</h1>
                  <h6 className="m-0 text-muted" style={{ inlineSize: 'max-content' }}>
                    Tickets promocionais
                  </h6>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <Row>
                <Col md={1} style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-grid' }}>
                  <FiShoppingBag size={40} />
                </Col>
                <Col md={6}>
                  <h1 className="mb-0">{promotionalInvoices}</h1>
                  <h6 className="m-0 text-muted" style={{ inlineSize: 'max-content' }}>
                    Compras promocionais realizadas
                  </h6>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="mb-4">
              <Row>
                <Col md={1} style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-grid' }}>
                  <GiPodiumWinner size={40} />
                </Col>
                <Col md={6}>
                  <h1 className="mb-0" style={{ inlineSize: 'max-content' }}>
                    {winner}
                  </h1>
                  <h6 className="m-0 text-muted" style={{ inlineSize: 'max-content' }}>
                    Ganhador do sorteio
                  </h6>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <h5 className="mb-4">Seus últimos pedidos contabilizados como promocionais</h5>
            <Table
              dataSource={data}
              loading={loading}
              columns={columns}
              locale={{ emptyText: 'Você não efetuou nenhuma compra durante a promoção.' }}
            />
          </Col>
          <Col md={4}>
            <Card className="mb-4">
              <h2>Como funciona a promoção?</h2>
              <p>
                A cada <b>R$1.00</b> comprado em nossa loja durante o periodo dos dias <b>24/03/2021 até 24/04/2021</b>{' '}
                será contabilizado 1 ticket para sua conta, quanto mais tickets você tiver maior a probabilidade de ser
                sorteado para ganhar uma caneca exclusiva com a logo do seu servidor juntamente com a logo da Atlas
                Plugins (Parceria).
              </p>
              <br />
              <h3>Quando será realizado o sorteio?</h3>
              <p>
                O sorteio será realizado no dia 24/04/2021, todas as compras do dia 24/04/2021 também serão
                contabilizadas até às 00:00.
              </p>
              <h3>Como será feito o sorteio?</h3>
              <p>
                Como forma de transparência a Atlas Plugins realizará um vídeo sorteando todos os tickets pelo site{' '}
                <a href="https://sorteador.com.br/">www.sorteador.com.br</a> onde será gerado um número aleatório que
                corresponderá a um ticket de uma pessoa que comprou durante o periodo definido. Ficou com dúvidas?{' '}
                <a href="https://www.youtube.com/watch?v=-MiTB2FNn-Y">Clique aqui para assistir o último sorteio</a>{' '}
                realizado pela Atlas Plugins em nosso canal do Youtube.
              </p>
              <h3>Eai bora comprar?</h3>
              <p>
                Agora você animou né? e ainda tem mais!, para aproveitar ainda mais essa promoção utilize o cupom{' '}
                <b>PROMOCAOCANECA</b> e receba <b>30%</b> de desconto em toda a nossa loja até o dia 24/04/2021
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}
