import styled from 'styled-components'
import media from 'styled-media-query'

export const Page = styled.div`
  width: 100%;
  min-height: 100vh;
  background: var(--white);
  background-size: 100% 300px;
`

export const Container = styled.main`
  position: relative;
  padding: 30px;
  overflow-x: hidden; //horizontal

  ${media.lessThan('medium')`
    padding: 0;
  `}

  ${media.between('medium', 'large')`
    padding: 0;
  `}
`

export const Content = styled.div`
  background: #f1f5f8;
  padding: 30px;
  border-radius: 1rem;
`
