import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'

import { Container } from './styles'

function Button({
  children,
  backgroundColor,
  loading,
  onClick,
  color,
  fitContent,
  margin,
  padding,
  layoutId
}) {
  return (
    <Container
      layoutId={layoutId}
      backgroundColor={backgroundColor}
      loading={loading ? 'true' : 'false'}
      color={color}
      onClick={onClick}
      margin={margin}
      padding={padding}
      fitContent={fitContent}
    >
      {loading ? <BeatLoader size={15} color={color} /> : children}
    </Container>
  )
}

export default Button
