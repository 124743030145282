import React, { createContext, useContext, useState } from 'react'

const PluginSectionContext = createContext({})

const PluginSectionProvider = ({ children }) => {
  const [morePlugins, setMorePlugins] = useState(true)
  const [page, setPage] = useState(0)

  return (
    <PluginSectionContext.Provider value={{ page, setPage, setMorePlugins, morePlugins }}>
      {children}
    </PluginSectionContext.Provider>
  )
}

const usePluginSectionContext = () => {
  const context = useContext(PluginSectionContext)
  return context
}

export { usePluginSectionContext, PluginSectionProvider }
