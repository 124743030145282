import React, { useState } from "react";
import { FiInfo } from "react-icons/fi";
import { Timeline, Drawer, Tabs } from "antd";
import HTML2React from "html2react";
import TimeAgo from "react-timeago";
import ptBrStrings from "react-timeago/lib/language-strings/pt-br";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

function PluginInfo({ data }) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <a
        href="!#"
        className="mr-2"
        onClick={(e) => {
          e.preventDefault()
          setVisible(true)
        }}
        style={{ fontSize: 22 }}
      >
        <FiInfo />
      </a>
      <Drawer
        title="Detalhes do Plugin"
        placement="bottom"
        closable={false}
        height="80vh"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Visão geral" key="1">
            {HTML2React(data.description)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Comandos" key="2">
            {HTML2React(data.commands)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Permissões" key="3">
            {HTML2React(data.permissions)}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Atualizações" key="4">
            <br />
            {data.updates.length > 0 ? (
              <Timeline pending={true}>
                {data.updates.map((el) => (
                  <Timeline.Item key={el.id}>
                    <h5 className="mb-0">{`${el.version}`}</h5>
                    <TimeAgo
                      date={el.createdAt}
                      formatter={buildFormatter(ptBrStrings)}
                    />
                    <hr />
                    {HTML2React(el.body)}
                  </Timeline.Item>
                ))}
              </Timeline>
            ) : (
              <h4>Não há atualizações para este plugin</h4>
            )}
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </>
  );
}

export default PluginInfo;
