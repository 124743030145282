import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import PrivateRoute from './components/PrivateRoute'

import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import Dashboard from './pages/Dashboard'
import Plugins from './pages/Plugins'
import PluginsLicenses from './pages/Plugins/licenses'
import Support from './pages/Support'
import SupportOpen from './pages/Support/open'
import SupportView from './pages/Support/view'
import Purchases from './pages/Purchases'
import Server from './pages/Server'
import Settings from './pages/Settings'
import Order from './pages/Order'
import Recover from './pages/Recover'
import Promotion from './pages/Promotion'
import NotFound from './pages/NotFound'
import SmsPage from './pages/Sms'
import PaymentSuccess from './pages/PaymentSuccess'
import PaymentError from './pages/PaymentError'
import PaymentPending from './pages/PaymentPending'

export default function App() {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route exact path="/" component={Home} />
        {/*<Route exact path="/:id" component={Home} /> */}
        <Route exact path="/plugins/:id" component={Home} />
        <Route exact path="/loja/detalhes/:id" component={Home} />
        <Route exact path="/encomendar" component={Order} />

        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/dashboard/plugins" component={Plugins} />
        <PrivateRoute exact path="/dashboard/promotion" component={Promotion} />
        <PrivateRoute exact path="/dashboard/plugins/:id/licencas" component={PluginsLicenses} />
        <PrivateRoute exact path="/dashboard/suporte" component={Support} />
        <PrivateRoute exact path="/dashboard/suporte/abrir" component={SupportOpen} />
        <PrivateRoute exact path="/dashboard/suporte/:id" component={SupportView} />
        <PrivateRoute exact path="/dashboard/compras" component={Purchases} />
        <PrivateRoute exact path="/dashboard/servidor" component={Server} />
        <PrivateRoute exact path="/dashboard/settings" component={Settings} />
        <PrivateRoute exact path="/dashboard/sms" component={SmsPage} />
        <PrivateRoute exact path="/dashboard/pagamento/sucesso" component={PaymentSuccess} />
        <PrivateRoute exact path="/dashboard/pagamento/error" component={PaymentError} />
        <PrivateRoute exact path="/dashboard/pagamento/pendente" component={PaymentPending} />
        <PrivateRoute exact path="/dashboard/*" component={NotFound} />

        <Route exact path="/login" component={Login} />
        <Route exact path="/cadastro" component={Register} />
        <Route exact path="/recuperar-senha" component={Recover} />
        <Route exact path="*" component={Home} />
      </Switch>
    </BrowserRouter>
  )
}
