import React, { useState } from 'react';
import { message } from 'antd'

import { Container } from './styles';

import api from '../../services/api'

function License({ data: { id, ip: defaultIp, port: defaultPort } }) {
  const [ip, setIp] = useState(defaultIp)
  const [port, setPort] = useState(defaultPort)
  const [loading, setLoading] = useState(false);

  async function submit() {
    if (!ip) return message.info('Informe o IP')
    if (!port) return message.info('Informe a porta')

    setLoading(true);

    try {
      await api.patch(`/license/${id}`, { ip, port })
      message.success('Licença atualizada')
    } catch (err) {
      message.error('Não foi possível salvar a licença')
    }

    setLoading(false);
  }

  return (
    <Container>
      <input placeholder="Informe o IP" value={ip} onChange={e => setIp(e.target.value)} />
      <input placeholder="Informe a porta" value={port} onChange={e => setPort(e.target.value)} />
      <button onClick={submit} disabled={loading}>
        {loading ? 'Salvando...' : 'Salvar'}
      </button>
    </Container>
  );
}

export default License;