import React, { useState } from 'react'
import * as EmailValidator from 'email-validator'
import { notification, Modal, message } from 'antd'
import { motion } from 'framer-motion' // not "framer-motion/dist/framer-motion"
import ReCAPTCHA from 'react-recaptcha'
import { Link } from 'react-router-dom'

import api from '../../services/api'
import { setToken, setUser } from '../../guard'
import { Card, Col, Grid, Page } from './styles'

import Steps from '../../components/Steps'
import Input from '../../components/Input'
import Button from '../../components/Button'

import logoSrc from '../../assets/images/logo-white.png'
import { Helmet } from 'react-helmet'

function Register({ history }) {
  const [visible, setVisible] = useState(false)

  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [discord, setDiscord] = useState('')
  const [response, setResponse] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const transition = {
    duration: 0.5,
    ease: [0.43, 0.13, 0.23, 0.96],
  }

  const backVariants = {
    exit: { x: 100, opacity: 0, transition },
    enter: { x: 0, opacity: 1, transition: { delay: 0, ...transition } },
  }

  const [step, setStep] = useState(0)
  const [completed, setCompleted] = useState(false)
  const [readTerms, setReadTerms] = useState(false)

  function isValidDiscordUsername(inputStr) {
    const pattern = /^\w{2,32}#\d{4}$/
    return pattern.test(inputStr)
  }

  async function submit() {
    switch (step) {
      case 0:
        if (!username || !email || !password || !repeatPassword) {
          return notification.warning({
            message: 'Revise seus dados',
            description: 'Informe todos os campos necessários.',
            placement: 'bottomRight',
          })
        }

        if (!EmailValidator.validate(email)) {
          return notification.warning({
            message: 'Revise seus dados',
            description: 'Endereço de e-mail inválido, tente outro.',
            placement: 'bottomRight',
          })
        }

        if (!readTerms) {
          return notification.warning({
            message: 'Revise seus dados',
            description: 'Aceite os termos & condições para continuar',
            placement: 'bottomRight',
          })
        }

        if (password.length > 30 || password.length < 6) {
          return notification.warning({
            message: 'Revise seus dados',
            description: 'Sua senha deve ter entre 6 a 30 caracteres',
            placement: 'bottomRight',
          })
        }

        if (password !== repeatPassword) {
          return notification.warning({
            message: 'Opa, calma ai!',
            description: 'Suas senhas não são iguais',
            placement: 'bottomRight',
          })
        }

        setLoading(true)

        try {
          await api.post('/user/register/step/1', {
            username,
            email,
          })

          setStep(step + 1)
        } catch (err) {
          notification.warning({
            message: 'Opa, calma ai!',
            description: err.response.data.message,
            placement: 'bottomRight',
          })
        }
        setLoading(false)
        break

      case 1:
        if (discord.length <= 0) {
          return notification.warning({
            message: 'Opa, calma ai!',
            description: 'Preencha o campo com seu Discord contendo o ID após o #',
            placement: 'bottomRight',
          })
        }

        if (!isValidDiscordUsername(discord)) {
          return notification.warning({
            message: 'Opa, username do Discord inválido!',
            description: 'Por favor, verifique seu username do Discord se está correto.',
            placement: 'bottomRight',
          })
        }

        setLoading(true)

        try {
          await api.post('/user/register/step/2', {
            discord,
          })

          setStep(step + 1)
        } catch (err) {
          notification.warning({
            message: 'Opa, calma ai!',
            description: err.response.data.message,
            placement: 'bottomRight',
          })
        }
        setLoading(false)

        break
      case 2:
        setStep(step + 1)
        break

      default:
        return notification.error({
          message: 'Ocorreu um erro',
          description: 'Tente atualizar sua página, ocorreu um erro :(',
          placement: 'bottomRight',
        })
    }
  }

  async function completeForm() {
    if (!username || !email || !password || !repeatPassword) {
      return notification.warning({
        message: 'Revise seus dados',
        description: 'Informe todos os campos necessários.',
        placement: 'bottomRight',
      })
    }

    if (!response) {
      return notification.warning({
        message: 'Revise seus dados',
        description: 'Informe todos os campos necessários.',
        placement: 'bottomRight',
      })
    }

    setLoading(true)

    try {
      const result = await api.post('/user', {
        username,
        email,
        password,
        discord,
        response,
      })
      const { user, token } = result.data

      setUser(user)
      setToken(token)

      history.push('/dashboard')

      message.success(`Bem-vindo, ${username}`)
    } catch (err) {
      window.grecaptcha.reset()
      return notification.error({
        message: 'Oops!, ocorreu um erro',
        description: err.response.data.message,
        placement: 'bottomRight',
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Criar uma conta - Atlas Plugins</title>
      </Helmet>
      <Page>
        <Grid>
          <Col>
            <Card layoutId="card">
              <Steps
                rows={[
                  { step: '1', title: 'Dados pessoais' },
                  { step: '2', title: 'Discord' },
                  { step: '3', title: 'Recaptcha' },
                ]}
                activeIndex={step}
              />
              {step === 0 && (
                <>
                  <Input
                    inputProps={{
                      placeholder: 'Informe seu nome',
                    }}
                    value={username}
                    onChange={setUsername}
                  />
                  <Input
                    inputProps={{
                      placeholder: 'Informe seu e-mail',
                    }}
                    value={email}
                    onChange={setEmail}
                  />
                  <Input
                    inputProps={{
                      placeholder: 'Informe uma senha',
                    }}
                    isPassword
                    value={password}
                    onChange={setPassword}
                  />
                  <Input
                    inputProps={{
                      placeholder: 'Repita sua senha',
                    }}
                    isPassword
                    value={repeatPassword}
                    onChange={setRepeatPassword}
                  />
                  <label className="terms-and-conditions">
                    <input
                      type="checkbox"
                      onClick={() => {
                        setReadTerms(true)
                      }}
                      required
                    />{' '}
                    Eu declaro que li e aceito todos os{' '}
                    <a href="#terms-and-services" onClick={setVisible}>
                      termos & condições da Atlas Plugins
                    </a>{' '}
                    e não descumprirei nenhuma regra.
                  </label>
                </>
              )}
              {step === 1 && (
                <>
                  <Input
                    inputProps={{
                      placeholder: 'Informe seu usuário do Discord',
                    }}
                    value={discord}
                    onChange={setDiscord}
                  />
                </>
              )}
              {step === 2 && (
                <>
                  <ReCAPTCHA
                    sitekey="6LdearEZAAAAAKktAO99pbj3B2ZN2aeScIlWoXhU"
                    size="normal"
                    render="explicit"
                    verifyCallback={(response) => {
                      setResponse(response)
                      setCompleted(true)
                    }}
                    onloadCallback={() => console.log('reCaptcha callback')}
                  />
                </>
              )}
              {step === 2 && completed && (
                <Button
                  backgroundColor="#000"
                  margin="25px 0px 10px 0px"
                  color="#fff"
                  loading={loading}
                  onClick={completeForm}
                >
                  Completar cadastro
                </Button>
              )}
              {step < 2 && (
                <Button backgroundColor="var(--primary)" color="#fff" loading={loading} onClick={submit}>
                  {step < 2 ? 'Prosseguir' : 'Concluir cadastro'}
                </Button>
              )}
              {step > 0 && step <= 2 && (
                <Button
                  backgroundColor="transparent"
                  color="#000"
                  margin={step === 2 && !completed ? '25px 0px 10px 0px' : '0px'}
                  onClick={() => {
                    setStep(step - 1)
                    setCompleted(false)
                  }}
                >
                  Voltar
                </Button>
              )}
            </Card>
          </Col>
          <Col right>
            <motion.div variants={backVariants}>
              <motion.img layoutId="atlasLogo" src={logoSrc} alt="Atlas Loja - Login" />
              <motion.h1 layoutId="atlasHeading">
                Faça seu servidor <br /> <b>voar nas núvens</b> <br /> sem obstáculos!
              </motion.h1>
              <p>
                Cadastre-se e faça parte da maior loja Brasileira de plugins <br /> de minecraft e faça seu servidor
                voar nas núvens.
              </p>
              <Link to="/login">Voltar para o login</Link>
            </motion.div>
          </Col>
        </Grid>
      </Page>
      <Modal
        title="Políticas de compra"
        visible={visible}
        cancelText="Fechar"
        okText="Concordo"
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        <h4 className="font-weight-bold">Atenção!</h4>
        <p>
          É proibida a utilização de qualquer modificação que tragam qualquer tipo de vantagem para quem os utiliza;{' '}
          <br />
          É proibido qualquer divulgação de qualquer plugin/serviço prestado por nós; <br />
          É proibido editar ou tentar decompilar nossos projetos, caso aconteça isso e seja descoberto corre risco de
          remoção da licença; <br />É EXTREMAMENTE PROIBIDO a divulgação de qualquer arquivo de nosso site para outras
          pessoas para serem divulgadas ou editadas ou decompiladas, correndo o risco do cancelamento total da licença
          do usuário sem reembolso.
        </p>
        <h4 className="font-weight-bold">Política de Reembolso</h4>
        <p>
          Após a finalização da compra em nossa loja, nos reservamos no direito de fornecer reembolso apenas em ocasiões
          em que nosso serviço pare de funcionar, caso contrário todo suporte possível que a equipe ATLAS PLUGINS será
          fornecido. <br />
          A entrega do produto é automática e sua licença é liberada assim que o pagamento é aprovado. Em caso de
          suspensão da conta do usuário, o reembolso não será feito, mesmo que tal suspensão impeça o usuário de
          usufruir dos benefícios adquridos. <br />
          Ao adquirir um produto em nossa loja, você concorda que está adquirindo produtos 100% virtuais e não passíveis
          de devolução.
        </p>
        <h4 className="font-weight-bold">Política de Privacidade</h4>
        <p>
          O grupo Atlas Plugins se firma no dever de manter todas as informações pessoais do usuário em sigílo total.
          Nunca distribuiremos ou venderemos informações existentes em nossos cadastros.
          <br />
          <br />O grupo de Discord da Atlas Plugins não libera a venda de nossos plugins pelo nosso discord (Os plugins
          comprados na loja podem ser vendidos por fora de nosso discord.)
        </p>
      </Modal>
    </>
  )
}

export default Register
