import axios from 'axios'
import { getToken, logout } from '../guard'

const http = axios.create({
  baseURL: 'https://api.atlasplugins.com/v1',
})

http.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    var {
      data: { message },
    } = error.response
    if (message === 'User not found' || error.response.status === 401) {
      logout()
      window.location.href = '/login'
    }

    return Promise.reject(error)
  }
)

export default http
