import React from 'react'
import { FiEyeOff, FiEye } from 'react-icons/fi'

import { Container, TextInput, Left, Right, HideButton } from './styles'

function Input({ inputProps, leftIcon, topLeftIcon = '24px', Icon, isPassword, value, onChange }) {
  const [show, setShow] = React.useState(isPassword ? false : true)

  return (
    <Container>
      {leftIcon && (
        <Left style={{ top: topLeftIcon }}>
          <Icon />
        </Left>
      )}
      <TextInput
        type={show ? 'text' : 'password'}
        hasLeft={leftIcon}
        hasRight={isPassword}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...inputProps}
      />
      {isPassword && (
        <Right>
          <HideButton onClick={() => setShow(!show)}>{!show ? <FiEye size="20" /> : <FiEyeOff size="20" />}</HideButton>
        </Right>
      )}
    </Container>
  )
}

export default Input
