import styled from 'styled-components'

export const Container = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: var(--primary);

  padding: 0;
  margin: 0;
  list-style-type: none;

  padding: 0 40px;
  padding-top: 40px;

  li {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    transition: all ease 0.3s;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      cursor: pointer;
    }

    svg {
      font-size: 22px;
      margin-right: 10px;
      color: #fff;
    }

    a {
      color: #fff;
      font-weight: 500;
      font-size: 16px;
    }

    &.actived {
      background: #f1f5f8;

      a {
        color: #000;
      }

      svg {
        color: var(--secondary);
      }
    }
  }
`
