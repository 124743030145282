/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { message, Button } from 'antd'
import { Row, Col } from 'reactstrap'

import { Container } from './styles'

import Plugin from '../Plugin'
import ShimmerProduct from '../ShimmerProduct'

import api from '../../services/api'
import { usePluginSectionContext } from '../../contexts/PluginSectionProvider'

function PluginsList() {
  const settings = useSelector((state) => state.settings)

  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [data, setData] = useState([])
  const { morePlugins, setMorePlugins, page, setPage } = usePluginSectionContext()

  async function getData() {
    setLoading(true)

    try {
      const result = await api.get(`/plugin/pagination/${settings.category}/${settings.type}/${settings.sort}/${page}`)

      setData(result.data)
    } catch (err) {
      message.error('Não foi possível buscar os plugins')
    }

    setLoading(false)
  }

  async function pagination(p) {
    setLoadingMore(true)

    try {
      const { data: dataResult } = await api.get(
        `/plugin/pagination/${settings.category}/${settings.type}/${settings.sort}/${p}`
      )

      setPage(p)
      setData(data.concat(dataResult))

      if (dataResult.length < 6) {
        setMorePlugins(false)
      } else {
        setMorePlugins(true)
      }
    } catch (err) {
      message.error('Erro ao procurar por produtos')
    }

    setLoadingMore(false)
  }

  useEffect(() => {
    getData()
  }, [settings])

  return (
    <Container>
      <Row>
        {loading ? (
          <React.Fragment>
            <Col md={4}>
              <ShimmerProduct />
            </Col>
            <Col md={4}>
              <ShimmerProduct />
            </Col>
            <Col md={4}>
              <ShimmerProduct />
            </Col>
          </React.Fragment>
        ) : (
          data.map((e) => (
            <Col key={e.id} md={4}>
              <Plugin data={e} />
            </Col>
          ))
        )}
      </Row>
      <div className="text-center">
        <Button
          type="primary"
          disabled={!morePlugins}
          loading={loadingMore}
          className="mr-2"
          onClick={() => pagination(page + 1)}
        >
          {loadingMore ? 'Aguarde...' : 'Mostrar mais'}
        </Button>
      </div>
    </Container>
  )
}

export default PluginsList
