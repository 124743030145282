import styled from 'styled-components'
import { motion } from 'framer-motion' // not "framer-motion/dist/framer-motion"

export const Container = styled(motion.button)`
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : '100%')};
  padding: ${({ padding }) => padding || '15px 20px'};
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  margin: ${({ margin }) => margin || '15px 0'};
  cursor: pointer;

  background: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ loading }) => (loading === 'true' ? '0.5' : '1')};
  color: ${({ color }) => color};

  transition: all ease 0.3s;

  &:hover {
    opacity: ${({ loading }) => (loading === 'true' ? '0.5' : '0.8')};
  }
`
