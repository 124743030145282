import React from 'react'
import AppLayout from '../../layouts/AppLayout'

export default function DashboardNotFound() {
  return (
    <AppLayout page="404" title="Página não encontrada">
      <div className="text-center">
        <img src={require('../../assets/images/no-server.svg')} alt="Atlas Plugins - Sem servidor" width="250" />
        <div>
          <h4 className="text-muted mt-3">Woops!, esta página não foi encontrada.</h4>
        </div>
      </div>
    </AppLayout>
  )
}
