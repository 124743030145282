/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { IoSettingsSharp } from 'react-icons/io5'
import { FiLogOut } from 'react-icons/fi'
import { FaShoppingCart } from 'react-icons/fa'

import { getUser } from '../../guard'
import { Container, Logo, Welcome, Links } from './styles'

import { logout } from '../../guard'
import api from '../../services/api'

function AppHeader() {
  const history = useHistory()

  async function exit(e) {
    e.preventDefault()
    await api.delete('/user/logout')
    logout()
    history.push('/login')
  }

  return (
    <Container>
      <Logo>
        <img
          src={require('../../assets/images/logo-white.png')}
          alt="Logo - AtlasPlugins"
          onClick={() => history.push('/')}
        />
        <h1>Atlas Plugins</h1>
      </Logo>
      <Welcome>
        <h2>Bem-vindo de volta, {getUser() ? getUser().username : ''}</h2>
      </Welcome>
      <Links>
        <li>
          <Link to="/">
            <FaShoppingCart style={{ marginBottom: '3px', marginRight: '2px' }} /> <span>Voltar ao início</span>
          </Link>
        </li>
        <li>
          <Link to="/dashboard/settings">
            <IoSettingsSharp style={{ marginBottom: '3px', marginRight: '2px' }} /> <span>Configurações</span>
          </Link>
        </li>
        <li>
          <a href="#" onClick={exit}>
            <FiLogOut style={{ marginBottom: '3px', marginRight: '2px' }} /> <span>Sair da conta</span>
          </a>
        </li>
      </Links>
    </Container>
  )
}

export default AppHeader
