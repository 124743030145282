export default {
  status: (status) => {
    switch (status) {
      case "OPENED":
        return "Aberto";
      case "WAITING":
        return "Aguardando resposta";
      case "REPLIED":
        return "Respondido";
      case "CLOSED":
        return "Fechado";
      default:
        return "Não binário";
    }
  },
};
