import styled from 'styled-components'
import media from 'styled-media-query'
import { motion } from "framer-motion" // not "framer-motion/dist/framer-motion"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`

export const List = styled.ul`
  display: flex;
  flex-direction: row;
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
`

export const Item = styled.li`
  position: relative;
  margin-right: 15px;
  font-size: 16px;
  background: ${({ active }) =>
    active ? 'var(--primary)' : 'rgba(0, 0, 0, 0.2)'};
  color: #fff;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 35px;
`

export const ItemActive = styled(motion.div)`
  position: absolute;
  top: -5px;
  left: -5px;
  background: transparent;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  border: solid 3px var(--primary);
`

export const Title = styled.p`
  font-size: 22px;
  font-weight: 600;
  line-height: 100%;
  margin-left: auto;
  color: #000;

  ${media.lessThan('medium')`
    margin-left: 0;
    margin-top: -20px;
    margin-bottom: 20px;
  `}
`
