import React, { useEffect, useState } from 'react'
import { Table, Tag, message } from 'antd'
import { Link } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'

import api from '../../services/api'

function Support({ history }) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)
    try {
      const result = await api.get('/ticket')
      setData(
        result.data.map((el) => {
          return {
            key: el.id,
            ...el,
          }
        })
      )
    } catch (err) {
      message.error('Ocorreu algum erro ao tentar buscar seus dados')
    }

    setLoading(false)
  }

  const columns = [
    {
      title: 'Assunto',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Relevância',
      dataIndex: 'priority',
      key: 'priority',
      render: (text) => {
        switch (text) {
          case 'LOW':
            return <Tag color="gold">Baixa</Tag>
          case 'MEDIUM':
            return <Tag color="purple">Médiana</Tag>
          case 'HIGH':
            return <Tag color="volcano">Alta</Tag>
          default:
            return <Tag>Indefinido</Tag>
        }
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString('pt-BR'),
    },
    {
      title: 'Última atualização',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleString('pt-BR'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        switch (text) {
          case 'OPENED':
            return <Tag color="green">Aberto</Tag>
          case 'WAITING':
            return <Tag color="geekblue">Aguardando resposta</Tag>
          case 'REPLIED':
            return <Tag color="cyan">Respondido</Tag>
          case 'CLOSED':
            return <Tag color="red">Fechado</Tag>
          default:
            return <Tag>Indefinido</Tag>
        }
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (text, record) => {
        return (
          <>
            <Link to={`/dashboard/suporte/${record.id}`} className="text-primary font-weight-bold text-uppercase">
              Abrir ticket
            </Link>
          </>
        )
      },
    },
  ]

  return (
    <AppLayout page="support" title="Suporte">
      <h5 className="mb-4">
        {/* Suporte
        <button className="float-right btn btn-dark btn-sm" onClick={() => history.push('/dashboard/suporte/abrir')}>
          Abrir chamado
  </button> */}
      </h5>
      {/* <Table dataSource={data} columns={columns} loading={loading} locale={{ emptyText: 'Não há tickets aberto' }} /> */}
      <div className="text-center">
        <img src={require('../../assets/images/discord-icon.svg')} alt="Atlas Plugins - Discord" width="450" />
        <div>
          <div>
            <img src={require('../../assets/images/stars.svg')} alt="" />
            <h4 style={{ fontWeight: 'bold' }} className="text-muted mt-3 mb-4">
              Nossos tickets agora estão no nosso Discord <br />
            </h4>
          </div>
          <button
            style={{
              borderRadius: '28px',
              padding: '16px 32px',
              fontSize: '20px',
              backgroundColor: '#5865f2',
              color: '#ffffff',
              fontWeight: 'bold',
            }}
            className="mb-5"
            onClick={() => window.open('https://discord.gg/J7MUSpC', 'blank')}
          >
            Entrar na Comunidade
          </button>
        </div>
      </div>
    </AppLayout>
  )
}

export default Support
