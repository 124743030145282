import styled from "styled-components";
import media from "styled-media-query";
export const DiscordCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 45px;
  border-radius: 10px;
  background: #7289da;

  svg {
    color: #fff;
    font-size: 72px;
    margin-right: 45px;
  }

  div {
    h3 {
      color: #fff;
      font-weight: 800;
      margin: 0;
    }

    p {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
    }

    button {
      background: #2c2f33;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 45px 0;
  margin-bottom: 15px;

  h5 {
    font-weight: 800;
    font-size: 38px;
    color: var(--tertiary);
    margin: 0;
  }

  p {
    margin: 0;
  }

  button {
    margin-left: 15px;
    padding: 15px 25px;
    border-radius: 8px;
    background: var(--secondary);
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    svg {
      font-size: 18px;
    }

    &.discord {
      background: #7289da;
    }
  }

  ${media.lessThan("medium")`
    flex-direction: column;
    align-items: flex-start;
    
    button {
      margin-top: 15px;
      margin-left: 0;
      margin-right: 15px;
      padding: 10px 15px;
      font-size: 14px;
    }
  `}

  ${media.between("medium", "large")`
    flex-direction: column;
    align-items: flex-start;
    
    button {
      margin-top: 15px;
      margin-left: 0;
      margin-right: 15px;
      padding: 10px 15px;
      font-size: 14px;
    }
  `}
`;
