import styled from "styled-components";

export const Container = styled.div``;

export const DiscordCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  padding: 45px;
  border-radius: 10px;
  background:	#7289da;

  svg {
    color: #fff;
    font-size: 72px;
    margin-right: 45px;
  }

  div {
    h3 {
      color: #fff;
      font-weight: 800;
      margin: 0;
    }

    p {
      font-size: 16px;
      color: rgba(255, 255, 255, .7)
    }

    button {
      background: #2c2f33;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
    }
  }
`;