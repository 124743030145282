import styled from 'styled-components'

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 25px;
`

export const Button = styled.button`
  padding: 10px !important;
  border-radius: 8px !important;
  margin: 5px;
  text-align: center !important;
  background: ${({ actived }) => (actived ? 'var(--secondary)' : 'rgba(0, 0, 0, 0.2)')}!important;
  color: ${({ actived }) => (actived ? 'var(--white)' : 'rgba(0, 0, 0, 0.4)')}!important;
  transition: all ease 0.3s;
`
