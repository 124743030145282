/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import axios from "axios";
import nl2br from "react-nl2br";
import copy from "copy-to-clipboard";

import { Item } from "./styles";

function PartnersItem({ data }) {
  const [visible, setVisible] = useState(false);
  const [players, setPlayers] = useState({
    online: "...",
    max: "...",
  });

  useEffect(() => {
    getPlayers();
  }, []);

  async function getPlayers() {
    const result = await axios.get(`https://api.mcsrvstat.us/2/${data.ip}`);

    setPlayers(result.data.players ? result.data.players : { online: 0, max: 0 });
  }

  return (
    <div>
      <Modal
        title={data.name}
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelText="Fechar"
        okText={`Copiar IP ${data.ip}`}
        onOk={() => {
          copy(data.ip);
          message.info("IP copiado! Bora jogar");
          setVisible(false);
        }}
      >
        <p>{nl2br(data.description)}</p>
        <br />
        <h6 className="font-weight-light">
          <b className="font-weight-bold">Players</b>: {players.online ? players.online : '?'}
          <b> / </b>
          {players.max ? players.max : '?'}
        </h6>
      </Modal>
      <Item onClick={() => setVisible(true)}>
        <img
          src={`https://api.atlasplugins.com/v1/file/image/${data.image}`}
          alt={`${data.name} - Atlas Plugins`}
        />
      </Item>
    </div>
  );
}

export default PartnersItem;
