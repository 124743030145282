import React, { useState, useEffect } from 'react'
import { Spinner } from 'reactstrap'
import { message } from 'antd'
import OwlCarousel from 'react-owl-carousel2'
import 'react-owl-carousel2/src/owl.carousel.css'

import { Container } from './styles'
import Item from './item'

import api from '../../services/api'

function PartnersSection() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const options = {
    items: 7,
    nav: false,
    rewind: true,
    autoplay: true,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 5,
      },
    },
  }

  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    setLoading(true)

    try {
      const result = await api.get('/user/servers')

      setData(result.data)
    } catch (err) {
      message.error('Ocorreu um erro ao tentar buscar os dados')
    }

    setLoading(false)
  }

  return (
    <Container>
      <h5>Servidores parceiros</h5>
      <p>que confiam na Atlas Plugins</p>
      {loading ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : (
        <OwlCarousel options={options} id="owl-light">
          {data.map((el) => (
            <Item key={`p-${el.id}`} data={el} />
          ))}
        </OwlCarousel>
      )}
    </Container>
  )
}

export default PartnersSection
