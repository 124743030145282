import React from 'react'

import { Container, List, Item, ItemActive, Title } from "./styles"

function Steps({ rows, activeIndex }) {
  return (
    <Container>
      <List>
        {rows.map((el, index) => (
          <Item key={index} active={index === activeIndex}>
            {el.step}
            {activeIndex === index && <ItemActive layoutId="stepId" />}
          </Item>
        ))}
      </List>
      <Title>{rows[activeIndex].title}</Title>
    </Container>
  )
}

export default Steps
