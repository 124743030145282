const INITIAL_STATE = {
  category: "all",
  type: "PAID",
  sort: "RECENT",
};

function settings(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_CATEGORY":
      return { ...state, category: payload };
    case "SET_TYPE":
      return { ...state, type: payload };
    case "SET_SORT":
      return { ...state, sort: payload };
    case "CLEAR":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default settings;
