import styled from "styled-components";

export const Container = styled.div`
  background: #2b223d;
  border-radius: 8px;
  padding: 45px;
  margin: 20px 0;

  h5 {
    font-weight: 800;
    font-size: 38px;
    color: #fff;
  }

  p {
    color: rgba(255, 255, 255, 0.8);
  }
`;
