import React from "react";
import { FaDiscord, FaEnvelope } from "react-icons/fa";

import { Container, DiscordCard } from "./styles";

function ContactSection() {
  return (
    <>
      <DiscordCard>
        <FaDiscord style={{ fontSize: "150px" }} />
        <div>
          <h3>Nossa comunidade</h3>
          <p>
            Com a ajuda de nossos youtubers e clientes, nós da Atlas Plugins
            temos uma comunidade exclusiva onde você pode tirar duvidas e até
            criar amigos para criar servidores junto com você em nosso Discord.
          </p>
          <button
            onClick={() => window.open("https://discord.gg/J7MUSpC", "blank")}
          >
            Entrar na Comunidade
          </button>
        </div>
      </DiscordCard>
      <Container>
        <div>
          <h5>Entre em contato</h5>
          <p>Em caso de dúvidas, críticas, suporte externo, etc.</p>
        </div>

        <div>
          <button
            onClick={() =>
              (window.location.href = "mailto:suporte@atlasplugins.com")
            }
          >
            <FaEnvelope className="mr-2" /> E-mail
          </button>
          <button
            className="discord"
            onClick={() => window.open("https://discord.gg/J7MUSpC", "_blank")}
          >
            <FaDiscord className="mr-2" /> Canal do Discord
          </button>
        </div>
      </Container>
    </>
  );
}

export default ContactSection;
