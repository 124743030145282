import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'reactstrap'
import { BiMailSend } from 'react-icons/bi'
import { FaCoins } from 'react-icons/fa'

import { Modal, Table, Tag, message, Input, Select } from 'antd'

import AppLayout from '../../layouts/AppLayout'
import api from '../../services/api'
import { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input'
import { Card, BuyButton, Container } from './styles'

export default function SmsPage() {
  const [credits, setCredits] = useState(80)
  const [search, setSearch] = useState('')
  const [payLoading, setPayLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [gateway, setGateway] = useState('MERCADOPAGO')
  const [loading, setLoading] = useState(true)
  const [canUse, setCanUse] = useState(true)
  const [sentSMS, setSentSMS] = useState(0)
  const [currentCredits, setCurrentCredits] = useState(0)
  const [data, setData] = useState([])

  useEffect(() => {
    getData() // eslint-disable-next-line
  }, [])

  async function getData() {
    try {
      const response = await api.get('/user/plugins')
      if (response.data) {
        const pluginsArray = response.data
        if (pluginsArray.some((plugin) => plugin.title === 'AtlasSMS')) {
          setCanUse(true)
          fetchData()
        }
      }
    } catch (err) {
      message.error('Não foi possível verificar os seus plugins, tente verificar a conexão com a internet.')
    }
    setLoading(false)
  }

  async function fetchData() {
    try {
      const response = await api.get('/user/sms')
      if (response.data) {
        const recivedData = response.data
        const { currentCredits, sentSMS, smsHistory } = recivedData
        setSentSMS(sentSMS)
        setCurrentCredits(currentCredits)
        setData(smsHistory)
      }
    } catch (err) {}
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => {
        return `# ${text}`
      },
    },
    {
      title: 'Jogador',
      dataIndex: 'player',
      key: 'player',
    },
    {
      title: 'Número de celular',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (text) => {
        return formatPhoneNumberIntl(text)
      },
    },
    {
      title: 'País',
      dataIndex: 'phone',
      key: 'country',
      align: 'center',
      render: (text) => {
        const country = parsePhoneNumber(text).country
        const flagUrl = `https://flag.pk/flags/4x3/${country}.svg`
        return <img src={flagUrl} alt="Bandeira do país" />
      },
    },
    {
      title: 'Status de Confirmação',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text) => {
        switch (text) {
          case 'NOT_CONFIRMED':
            return <Tag color="geekblue">Aguardando confirmação</Tag>
          case 'CONFIRMED':
            return <Tag color="green">Confirmado</Tag>
          default:
            return <Tag>Indefinido</Tag>
        }
      },
    },
    {
      title: 'Créditos utilizados',
      dataIndex: 'credits',
      key: 'credits',
      align: 'center',
      render: (text) => {
        return <Tag color="red">-{text} crédito</Tag>
      },
    },
    {
      title: 'SMS enviado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['xxl', 'xl', 'lg'],
      render: (text) => new Date(text).toLocaleString('pt-BR'),
    },
  ]

  function filterData() {
    return data.filter((res) => {
      return JSON.stringify(res).toLocaleLowerCase().match(search.toLocaleLowerCase())
    })
  }

  function calculateValue() {
    if (credits <= 0) {
      return 'R$0,00'
    }

    var price = credits * 0.2
    var fee = price * 0.05
    var finalPrice = price + fee
    const formated = parseFloat(finalPrice).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return `R$ ${formated}`
  }

  async function pay() {
    if (credits <= 0) {
      message.error('Por favor, digite valores maiores que 0 para finalizar a compra de saldo.')
      return
    }
    if (credits < 80) {
      message.error('Ops!, o mínimo de saldo é de 80 sms.')
      return
    }
    setPayLoading(true)

    try {
      const {
        data: { link },
      } = await api.post(`/user/sms/pay/${gateway}`, { credits })

      window.location.href = link
    } catch (err) {
      message.error('Ocorreu um erro durante o processamento do pagamento, por favor tente relogar.')
    }

    setPayLoading(false)
  }

  return (
    <AppLayout page="sms" title="Configuração do plugin AtlasSMS">
      {loading ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : canUse ? (
        <Container>
          <h5 className="mb-4">AtlasSMS - Configurações</h5>
          <div>
            <Modal
              title="Adicionar saldo de SMS"
              visible={visible}
              okText="Finalizar a compra"
              cancelText="Cancelar compra"
              okButtonProps={{ loading: payLoading }}
              onCancel={() => setVisible(false)}
              onOk={pay}
            >
              <h5>Adicionar saldo de SMS</h5>
              <p>
                Compre créditos para que seu plugin possa funcionar enviando SMS para os seus jogadores in-game
                totalmente automático.
              </p>
              <br />
              <h5>Valores</h5>
              <p>
                A cada <b>1 sms = R$ 0,20 + (taxas do gateway)</b>, utilize o calculador automático do valor que deverá
                ser pago abaixo.
              </p>
              <br />
              <p>
                <b>Atenção:</b> Para jogadores que utilizarem o DDD +351 (Portugal) será cobrado 2 créditos (2 x R$0,20
                = R$0,40)
              </p>
              <Input
                placeholder="Digite o valor de créditos que você deseja comprar"
                size="large"
                allowClear={false}
                type="number"
                prefix={<FaCoins style={{ marginRight: '10px' }} />}
                suffix={calculateValue()}
                value={credits}
                onChange={(e) => {
                  var value = e.target.value
                  if (value.length <= 10 && value >= 0) {
                    setCredits(value)
                  }
                }}
              />
              <br />
              <br />
              <h5>Selecione a forma de pagamento:</h5>
              <Select value={gateway} onChange={setGateway} style={{ width: '100%' }}>
                <Select.Option value="MERCADOPAGO">MercadoPago</Select.Option>
                <Select.Option value="PAYPAL">PayPal</Select.Option>
              </Select>
            </Modal>
            <Row>
              <Col md={9}>
                <Row>
                  <Col md={6} style={{ height: '100%' }}>
                    <Card className="mb-6">
                      <Row style={{ display: 'flex', alignItems: 'center' }}>
                        <Col md={1} style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-grid' }}>
                          <FaCoins size={40} />
                        </Col>
                        <Col md={6}>
                          <h1 className="mb-0">{currentCredits}</h1>
                          <h6 className="m-0 text-muted" style={{ inlineSize: 'max-content' }}>
                            Seus créditos de SMS
                          </h6>
                        </Col>
                        <Col md={5}>
                          <BuyButton onClick={() => setVisible(!visible)}>Comprar saldo</BuyButton>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col md={6} style={{ height: '100%' }}>
                    <Card className="mb-6">
                      <Row>
                        <Col md={1} style={{ justifyContent: 'center', alignItems: 'center', display: 'inline-grid' }}>
                          <BiMailSend size={40} />
                        </Col>
                        <Col md={6}>
                          <h1 className="mb-0">{sentSMS}</h1>
                          <h6 className="m-0 text-muted" style={{ inlineSize: 'max-content' }}>
                            SMS enviados pelo seu servidor
                          </h6>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <h5 className="mb-4" style={{ paddingTop: '20px' }}>
                      Últimos números de celulares verificados
                    </h5>
                    <Input
                      block
                      placeholder="Pesquise pelo nome de usuário ou número de celular confirmado"
                      value={search}
                      style={{ borderRadius: '10px' }}
                      onChange={(e) => setSearch(e.target.value)}
                      size="large"
                    />
                    <br />
                    <br />
                    <Table
                      dataSource={search.length > 0 ? filterData() : data}
                      loading={loading}
                      columns={columns}
                      locale={{ emptyText: 'Nenhum número de celular foi solicitado através da sua API.' }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <Card className="mb-4">
                  <h2>Sistema de créditos</h2>
                  <p>
                    Compre créditos e utilize eles para que seus jogadores possam verificar o número de celular pelo seu
                    servidor.
                  </p>
                  <p>
                    Quando seus créditos acabarem o console do seu servidor irá avisá-lo para que você recarregue seu
                    saldo nesta página.
                  </p>
                  <h2>Porque cobramos por créditos?</h2>
                  <p>
                    Todo SMS enviado tanto nacionalmente quanto internacionalmente é cobrado um valor em centavos de
                    dólar e dependendo do prefixo do telefone (+55 ou +351) é cobrado uma taxa em cima deste valor e por
                    isso cobramos mais créditos para telefones com DDD +351
                  </p>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      ) : (
        <div className="text-center">
          <img src={require('../../assets/images/no-server.svg')} alt="Atlas Plugins - Novidades" width="250" />
          <div>
            <h4 className="text-muted mt-3">
              Ops!, você não possui o plugin <b>AtlasSMS</b>, portanto você não pode acessar esta aba.
            </h4>
          </div>
        </div>
      )}
    </AppLayout>
  )
}
