import styled from "styled-components";

export const Plugin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ launcher }) => launcher ? '32.5px 30px' : '20px 30px'};
  background: ${({ launcher }) => launcher ? 'var(--primary)' : 'var(--white)'};
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 20px #0000000b;

  img {
    width: 45px;
    margin-right: 30px;
  }

  p,
  h5 {
    margin: 0;
    color: ${({ launcher }) => launcher && 'var(--white)'};
  }

  p {
    text-transform: uppercase;
    font-size: 12px;
    color: ${({ launcher }) => launcher ? 'var(--white)' : 'var(--muted)'};
  }

  span {
    font-size: 10px;
  }

  button {
    padding: 5px 10px;
    border-radius: 3px;
    margin-right: 10px;
    background: ${({ launcher }) => launcher ? 'var(--white)' : 'var(--primary)'};
    color: ${({ launcher }) => launcher ? '#000' : 'var(--white)'};
  }

  a {
    color: var(--secondary);
  }
`;

export const AddLicense = styled.button`
  width: 100%;
  height: 210px;
  background: rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  text-align: center;
  border-radius: 8px;
  color: rgba(0, 0, 0, .2);
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
`