import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`;

export const Image = styled.div`
  position: relative;
  width: 160px;
  border-radius: 5px;
  height: 220px;
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.08);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 60px;
  }
`;

export const Body = styled.div`
  padding: 15px;
  padding-right: 0;
  width: calc(100% - 160px);
`;

export const CategoryName = styled.div`
  color: var(--muted);
  font-size: 16px;
  font-weight: 300;
`;

export const Title = styled.div`
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Price = styled.div`
  font-size: 26px;
  margin-bottom: 15px;
  font-weight: 600;
`;

export const Buttons = styled.div``;

export const Button = styled.button`
  border-radius: 5px;
  margin-right: 5px;
  font-weight: 600;
  padding: ${({ transparent }) => (transparent ? "5px" : "5px 15px")};
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : "var(--primary)"};
  color: ${({ transparent }) =>
    transparent ? "var(--symbol)" : "var(--white)"};

  &:focus {
    outline: none;
  }

  svg {
    font-size: 24px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;

  img {
    max-width: 60px;
    margin-right: 60px;
    margin-left: 40px;
  }

  h4 {
    margin: 0;
  }

  h6 {
    margin: 0;
  }

  h5 {
    margin: 10px 0;
  }

  button {
    padding: 5px 15px;
    background: var(--primary);
    border-radius: 5px;
    color: var(--white);
    margin-right: 15px;
  }
`;
