import React from 'react'
import { Container, Image } from './styles'

function HomePresentation() {
  return (
    <Container>
      <div>
        <h1>
          <b>Uma nova ATLAS. Um novo começo.</b> <br />
          <span>Agora com nova gestão</span>
        </h1>
        <button href="https://atlasplugins.com/login">Entre na comunidade</button>
      </div>
      <Image/>
    </Container>
  )
}

export default HomePresentation