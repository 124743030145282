/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Input, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { FiArrowRight, FiTag, FiShoppingBag } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'

import { Buttons, Button } from './styles'
import CartItem from '../CartItem'

import convertCurrency from '../../services/currency'
import { setCurrency } from '../../store/actions/cart.actions'

import { isLogged } from '../../guard'

import api from '../../services/api'

export default function Cart({ onCloseCart }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const [visible, setVisible] = useState(false)
  const [cupomLoading, setCupomLoading] = useState(false)
  const [cupom, setCupom] = useState('')
  const [discount, setDiscount] = useState(null)
  const [gateway, setGateway] = useState('MERCADOPAGO')
  const [loading, setLoading] = useState(false)
  const [promotionals, setPromotionals] = useState(0)
  const [subtotal, setSubtotal] = useState(0)

  const cart = useSelector((state) => state.cart)

  useEffect(() => {
    recalc()
  }, [cart])

  async function recalc() {
    var total = 0
    var promotionals = 0

    cart.products.map((product) => {
      total = total + product.price

      if (product.licenses.length > 1) {
        total = total + (product.licenses.length - 1) * 5
      }
      if (product.promotional > 0) {
        promotionals += product.price
      }
      return true
    })

    if (total === 0) {
      return setSubtotal(total)
    }

    if (cart.currency !== 'BRL') {
      total = await convertCurrency({
        amount: total,
        from: 'BRL',
        to: cart.currency,
      })
      promotionals = await convertCurrency({
        amount: promotionals,
        from: 'BRL',
        to: cart.currency,
      })
    }

    setPromotionals(promotionals)
    setSubtotal(total)
  }

  async function apply() {
    if (cupom.length === 0) return false

    setCupomLoading(true)

    try {
      setCupom('')
      const result = await api.get(`/discount/${cupom}`)

      if (result.data && result.data.message) {
        setCupom('')
        setCupomLoading(false)
        message.info('Este cupom não foi encontrado ou expirou ou atingiu o máximo de usos.')
        return false
      }

      const { amount, type } = result.data

      if (type === 'AMOUNT') {
        if (amount >= subtotal) {
          setCupomLoading(false)
          return message.warning(
            `Valor mínimo de compra é de R$${parseFloat(amount).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          )
        }
      }

      if (cart.products.some((product) => product.promotional)) {
        cart.products.forEach((product) => {
          console.log(product)
        })
        message.info('Atenção, alguns produtos não receberam desconto pois já contém seus preços em promoção.')
      }

      setDiscount(result.data)
    } catch (err) {
      message.error(err.response.data.message)
    }
    setVisible(false)
    setCupomLoading(false)
  }

  async function pay() {
    setLoading(true)

    try {
      const {
        data: { link },
      } = await api.post(`/checkout/pay/${gateway}`, {
        products: cart.products,
        cupom,
        discount,
      })
      window.location.href = link
    } catch (err) {
      message.error('Ocorreu um erro ao processar sua transação, por favor tente novamente mais tarde.')
    }

    setLoading(false)
  }

  function getDiscount() {
    if (!discount)
      return {
        subtotal,
        amount: 0,
      }

    if (discount.type === 'AMOUNT') {
      return {
        subtotal: subtotal - promotionals - discount.amount + promotionals,
        amount: discount.amount,
      }
    }

    if (subtotal === 0)
      return {
        subtotal,
        amount: 0,
      }

    var minus = ((subtotal - promotionals) * discount.amount) / 100

    return {
      subtotal: subtotal - promotionals - minus + promotionals,
      amount: minus,
    }
  }

  return (
    <React.Fragment>
      <Modal
        title="Aplicar cupom"
        visible={visible}
        onOk={apply}
        onCancel={() => setVisible(false)}
        okButtonProps={{ loading: cupomLoading }}
        okText="Aplicar"
        cancelText="Cancelar"
      >
        <Input
          size="large"
          placeholder="Cupom de desconto"
          value={cupom}
          onChange={(e) => setCupom(e.target.value)}
          prefix={<FiTag />}
        />
      </Modal>
      <ul>
        <li className="ml-0 mr-auto">
          <buttton
            className={`btn-currency ${cart.currency === 'BRL' && 'actived'}`}
            onClick={() => dispatch(setCurrency('BRL'))}
          >
            BRL
          </buttton>
          <buttton
            className={`btn-currency ${cart.currency === 'USD' && 'actived'}`}
            onClick={() => {
              dispatch(setCurrency('USD'))
              setGateway('PAYPAL')
            }}
          >
            USD
          </buttton>
          <buttton
            className={`btn-currency ${cart.currency === 'EUR' && 'actived'}`}
            onClick={() => {
              dispatch(setCurrency('EUR'))
              setGateway('PAYPAL')
            }}
          >
            EUR
          </buttton>
        </li>
        <li className="cart-count">{cart.products.length}</li>
        <li className="cart-close" onClick={() => onCloseCart()}>
          Fechar
        </li>
      </ul>
      <h3>
        <b>Meu</b> <br />
        pedido
      </h3>
      <section className={`${cart.products.length === 0 && 'mt-auto h-auto'}`}>
        {cart.products.length > 0 ? (
          cart.products.map((product) => (
            <CartItem key={`product-${product.id}`} image={product.image} data={product} />
          ))
        ) : (
          <div className="w-100 text-center mb-5">
            <FiShoppingBag size={86} color="#616161" />
          </div>
        )}
      </section>
      <div className="text-center pb-3">
        {discount ? (
          <a
            href="#remove-cupom"
            className="text-muted"
            style={{ fontSize: 14 }}
            onClick={(e) => {
              e.preventDefault()

              setDiscount(null)
              setCupom('')
            }}
          >
            remover cupom
          </a>
        ) : (
          <a
            href="#add-cupom"
            className="text-muted"
            style={{ fontSize: 14 }}
            onClick={(e) => {
              e.preventDefault()
              setVisible(true)
            }}
          >
            aplicar cupom
          </a>
        )}
      </div>
      <Buttons>
        {cart.currency === 'BRL' && (
          <Button actived={gateway === 'MERCADOPAGO'} onClick={() => setGateway('MERCADOPAGO')}>
            MercadoPago
          </Button>
        )}
        <Button actived={gateway === 'PAYPAL'} onClick={() => setGateway('PAYPAL')}>
          PayPal
        </Button>
      </Buttons>
      <div style={{ padding: 25 }}>
        <p className="m-0">
          Total:
          <span className="float-right">
            {cart.currency === 'BRL' && 'R$'}
            {subtotal.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            {cart.currency === 'USD' && '$'}
            {cart.currency === 'EUR' && '€'}
          </span>
        </p>
        <p className="m-0 mb-2">
          Desconto:{' '}
          <span className="float-right">
            {cart.currency === 'BRL' && 'R$'}
            {parseFloat(getDiscount().amount).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            {cart.currency === 'USD' && '$'}
            {cart.currency === 'EUR' && '€'}
          </span>
        </p>
        <h4>
          Subtotal:
          <span className="float-right">
            {cart.currency === 'BRL' && 'R$'}
            {parseFloat(getDiscount().subtotal).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            {cart.currency === 'USD' && '$'}
            {cart.currency === 'EUR' && '€'}
          </span>
        </h4>
      </div>
      <div style={{ paddingLeft: 25, paddingBottom: 25 }}>
        <button
          onClick={() => (isLogged() ? pay() : history.push('/login'))}
          disabled={loading || cart.products.length === 0}
        >
          {loading ? 'Aguarde...' : 'finalizar compra'} <FiArrowRight />
        </button>
      </div>
    </React.Fragment>
  )
}
