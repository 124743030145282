import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  .image {
    width: 160px;
    height: 220px;
    border-radius: 5px;
  }

  .body {
    padding: 15px;
    padding-right: 0;
    width: calc(100% - 175px);
  }

  .category {
    width: 65px;
    height: 16px;
    border-radius: 16px;
    margin-bottom: 4px;
  }

  .title {
    width: 80%;
    height: 20px;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .price {
    width: 60%;
    height: 26px;
    border-radius: 26px;
    margin-bottom: 15px;
  }

  .button {
    width: 50%;
    height: 32px;
    border-radius: 32px;
  }
`;
