import styled from "styled-components";

export const Container = styled.div`
  padding: 40px 0;

  h5 {
    text-align: center;
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    color: var(--tertiary);
  }

  p {
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    width: auto !important;
    max-width: 180px;
    max-height: 100%;
    transition: all ease 0.3s;
    cursor: pointer;

    &:hover {
      z-index: 999;
      transform: scale(1.1)
    }
  }
`;
