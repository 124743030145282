import React from "react";
import Shimmer from "react-shimmer-effect";

import { Container } from "./styles";

function ShimmerCategory() {
  return (
    <Container>
      <Shimmer>
        <div key={`icon-${Math.random()}`} className="icon" />
        <div key={`title-${Math.random()}`} className="title" />
      </Shimmer>
    </Container>
  );
}

export default ShimmerCategory;
