import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FiCornerRightDown } from 'react-icons/fi'

import { Container, Title, Buttons, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from './styles'

import { setSort, setType } from '../../store/actions/settings.actions'

function PluginHeader() {
  const dispatch = useDispatch()
  const settings = useSelector((state) => state.settings)

  const [filter1, setFilter1] = useState(false)
  const [filter2, setFilter2] = useState(false)

  return (
    <Container>
      <Title>Plugins</Title>
      <Buttons>
        <Dropdown isOpen={filter1} toggle={() => setFilter1(!filter1)}>
          <DropdownToggle>
            {settings.sort === 'POPULARITY'
              ? 'popularidade'
              : settings.sort === 'LOWEST_PRICE'
              ? 'menor preço'
              : settings.sort === 'RECENT'
              ? 'mais recente'
              : 'maior preço'}{' '}
            <FiCornerRightDown />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => dispatch(setSort('RECENT'))}>mais recentes</DropdownItem>
            <DropdownItem onClick={() => dispatch(setSort('POPULARITY'))}>popularidade</DropdownItem>
            <DropdownItem onClick={() => dispatch(setSort('LOWEST_PRICE'))}>menor preço</DropdownItem>
            <DropdownItem onClick={() => dispatch(setSort('BIGGEST_PRICE'))}>maior preço</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown isOpen={filter2} toggle={() => setFilter2(!filter2)}>
          <DropdownToggle star="true">
            {settings.type === 'PAID' ? 'plugins pagos' : 'plugins gratuitos'} <FiCornerRightDown />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => dispatch(setType('PAID'))}>plugins pagos</DropdownItem>
            <DropdownItem onClick={() => dispatch(setType('FREE'))}>plugins gratuitos</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Buttons>
    </Container>
  )
}

export default PluginHeader
