import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col, Modal } from 'antd'
import { FiShoppingBag } from 'react-icons/fi'
import { useSelector } from 'react-redux'

import { Grid, Wrapper, Cart as CartContent, CartButton, Footer } from './styles'
import { Styles } from '../../components'

import Menu from '../../components/Menu'
import Cart from '../../components/Cart'
import { Link } from 'react-router-dom'

export default function DefaultLayout({ children, title, pageUrl }) {
  const [visible, setVisible] = useState(false)
  const [cartVisible, setCartVisible] = useState(false)

  const cart = useSelector((state) => state.cart)

  return (
    <React.Fragment>
      <Styles />
      <Helmet>
        <title>{title} - Atlas Plugins</title>
        <link rel="canonical" href={`https://atlasplugins.com.br${pageUrl}`} />
        <style>
          {
            '\
        #tidio-chat-iframe {\
        right: 400px !important;\
        }\
        @media(max-width: 1170px) {\
           #tidio-chat-iframe {\
        right: 0 !important;\
        }\
        }\
        '
          }
        </style>
      </Helmet>
      <Grid cartOpen={true}>
        <Wrapper className="mx-auto" cartOpen={true}>
          <Menu pageUrl={pageUrl} />
          {children}
          <Footer>
            <Row gutter={16}>
              <Col span={24} md={11}>
                <h5>Copyright &copy; Atlas Plugins 2018-{new Date().getFullYear()}</h5>
                <h6>Este site não possui quaisquer vinculos com a Mojang AB.</h6>
              </Col>
              <Col span={24} md={9}>
                <ul>
                  <li>
                    <a
                      href="!#"
                      onClick={(e) => {
                        e.preventDefault()
                        setVisible(true)
                      }}
                    >
                      Políticas de Compra
                    </a>
                  </li>
                  <li>
                    <Link to="/dashboard/suporte">Suporte</Link>
                  </li>
                  <li>
                    <Link to="/cadastro">Cadastrar</Link>
                  </li>
                  <li>
                    <a href="https://docs.atlasplugins.com">Documentação de API</a>
                  </li>
                </ul>
              </Col>
              <Col span={24} md={4} className="text-md-right"></Col>
            </Row>
          </Footer>
        </Wrapper>
        <CartContent visible={cartVisible}>
          <Cart onCloseCart={() => setCartVisible(false)} />
        </CartContent>
      </Grid>
      <Modal
        title="Políticas de compra"
        visible={visible}
        cancelText="Fechar"
        okText="Concordo"
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        <h4 className="font-weight-bold">Atenção!</h4>
        <p>
          É proibida a utilização de qualquer modificação que tragam qualquer tipo de vantagem para quem os utiliza;{' '}
          <br />
          É proibido qualquer divulgação de qualquer plugin/serviço prestado por nós; <br />
          É proibido editar ou tentar decompilar nossos projetos, caso aconteça isso e seja descoberto corre risco de
          remoção da licença; <br />É EXTREMAMENTE PROIBIDO a divulgação de qualquer arquivo de nosso site para outras
          pessoas para serem divulgadas ou editadas ou decompiladas, correndo o risco do cancelamento total da licença
          do usuário sem reembolso.
        </p>
        <h4 className="font-weight-bold">Política de Reembolso</h4>
        <p>
          Após a finalização da compra em nossa loja, nos reservamos no direito de fornecer reembolso apenas em ocasiões
          em que nosso serviço pare de funcionar, caso contrário todo suporte possível que a equipe ATLAS PLUGINS será
          fornecido. <br />
          A entrega do produto é automática e sua licença é liberada assim que o pagamento é aprovado. Em caso de
          suspensão da conta do usuário, o reembolso não será feito, mesmo que tal suspensão impeça o usuário de
          usufruir dos benefícios adquridos. <br />
          Ao adquirir um produto em nossa loja, você concorda que está adquirindo produtos 100% virtuais e não passíveis
          de devolução.
        </p>
        <h4 className="font-weight-bold">Política de Privacidade</h4>
        <p>
          O grupo Atlas Plugins se firma no dever de manter todas as informações pessoais do usuário em sigílo total.
          Nunca distribuiremos ou venderemos informações existentes em nossos cadastros.
          <br />
          <br />O grupo de Discord da Atlas Plugins não libera a venda de nossos plugins pelo nosso discord (Os plugins
          comprados na loja podem ser vendidos por fora de nosso discord.)
        </p>
      </Modal>
      {cart.products.length > 0 && (
        <CartButton onClick={() => setCartVisible(true)}>
          <FiShoppingBag />
        </CartButton>
      )}
    </React.Fragment>
  )
}
