import styled from 'styled-components'
import { motion } from 'framer-motion'
import media from 'styled-media-query'

const transition = {
  duration: 1,
  ease: [0.43, 0.13, 0.23, 0.96],
}

const backVariants = {
  exit: { y: 100, opacity: 0, transition },
  enter: { y: 0, opacity: 1, transition: { delay: 0, ...transition } },
}

export const Page = styled(motion.div).attrs({
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: var(--primary);

  * {
    border: none;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  img {
    max-width: 80px;
    margin-bottom: 15px;
  }

  h1 {
    color: #fff;
    font-size: 24px;
  }

  ${media.lessThan('medium')`
    align-items: center;
    justify-content: center;

    img {
      max-width: 40px;
    }
  `}
`

export const Card = styled(motion.form).attrs({
  variants: backVariants,
})`
  width: 500px;
  background: #fff;
  border-radius: 5px;
  padding: 60px;
  margin: 20px 0;
  box-shadow: 0 70px 45px -40px rgba(0, 0, 0, 0.08), 0 10px 30px -5px rgba(0, 0, 0, 0.1);

  p {
    margin-bottom: 20px;
    color: #686868;
    font-size: 14px;
  }

  ${media.lessThan('medium')`
    padding: 30px;
    max-width: 90%;
  `}
`
