import React, { useEffect } from 'react'

import { Modal } from 'antd'
import Dashboard from '../Dashboard'

export default function PaymentError() {
  useEffect(() => {
    Modal.error({
      title: 'Pagamento não processado',
      content:
        'Woops!, seu pagamento não foi processado corretamente pelo gateway selecionado, tente refazer a compra.',
    })
  }, [])

  return <Dashboard />
}
