import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  background: #fff;
  border-radius: 20px;
  padding: 30px 22px;
  -webkit-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.2);
  z-index: 99;
  margin: 10px;
  cursor: pointer;

  .icon {
    width: 72px;
    height: 72px;
    border-radius: 72px;
  }

  .title {
    width: 75px;
    height: 14px;
    border-radius: 14px;
    margin-bottom: 5px;
  }
`;
