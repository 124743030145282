import React from "react";
import Shimmer from "react-shimmer-effect";

import { Container } from "./styles";

function ShimmerProduct() {
  return (
    <Container>
      <Shimmer>
        <div className="image" />
      </Shimmer>
      <div className="body">
        <Shimmer>
          <div className="category" />
          <div className="title" />
          <div className="price" />
          <div className="button" />
        </Shimmer>
      </div>
    </Container>
  );
}

export default ShimmerProduct;
