import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin: 8px 0;

  img {
    width: 40px;
    margin-right: 15px;
  }

  div {
    margin-right: auto;

    h5 {
      margin: 0;
      font-size: 16px;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      font-size: 12px;
      line-height: 12px;
    }
  }

  button {
    position: relative;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
    margin: 0;
    background: transparent;
    transition: all ease 0.3s;
    color: rgba(0, 0, 0, 0.4);

    svg {
      position: relative;
    }

    &:hover {
      color: tomato;
    }
  }
`;
