const INITIAL_STATE = {
  products: [],
  currency: "BRL",
  updater: 0,
};

function cart(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  var oldState = state;
  var idx, products;

  switch (type) {
    case "ADD_PRODUCT":
      if (!state.products.find((el) => el.id === payload.id)) {
        products = [...state.products, payload];

        return {
          ...state,
          products,
          updater: state.updater + 1,
        };
      }

      return {
        ...state,
      };
    case "REMOVE_PRODUCT":
      idx = state.products.findIndex((el) => el.id === payload);

      oldState.products[idx] = undefined;
      products = oldState.products.filter((el) => el !== undefined);

      return { ...state, products };
    case "ADD_LICENSE":
      idx = state.products.findIndex((el) => el.id === payload);

      oldState.products[idx].licenses.push({
        ip: "0.0.0.0",
        port: "2556" + (5 + oldState.products[idx].licenses.length),
      });

      return {
        ...oldState,
        updater: state.updater + 1,
      };
    case "EDIT_LICENSE":
      idx = state.products.findIndex((el) => el.id === payload.id);

      oldState.products[idx].licenses[payload.idx] = {
        ip: payload.ip,
        port: payload.port,
      };

      return {
        ...oldState,
        updater: state.updater + 1,
      };
    case "REMOVE_LICENSE":
      idx = state.products.findIndex((el) => el.id === payload.id);

      oldState.products[idx].licenses[payload.idx] = undefined;
      var licenses = oldState.products[idx].licenses.filter(
        (el) => el !== undefined
      );

      oldState.products[idx].licenses = licenses;

      return {
        ...oldState,
        updater: state.updater + 1,
      };
    case "SET_CURRENCY":
      return {
        ...oldState,
        currency: payload,
      };
    case "CLEAR":
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default cart;
