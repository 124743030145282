import styled from 'styled-components'

export const Container = styled.div`
  @media (max-width: 1655px) {
    h1 {
      font-size: xx-large;
    }
  }
  @media (max-width: 1373px) {
    h1 {
      font-size: x-large;
    }
  }
  @media (max-width: 1200px) {
    h1 {
      font-size: large;
    }
    h6 {
      font-size: small;
      word-wrap: break-word;
      inline-size: auto;
    }
  }
  @media (max-width: 767px) {
    h1 {
      font-size: xx-large;
    }
  }
`

export const Card = styled.div`
  position: relative;
  padding: 40px;
  border-radius: 8px;
  background: var(--white);
  z-index: 99;
  box-shadow: 0px 3px 20px #0000000b;
`
