import styled from 'styled-components'
import media from 'styled-media-query'

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  height: ${({ cartOpen }) => (cartOpen ? '100vh' : 'auto')};
  transition: all ease 0.3s;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column;
  `}

  ${media.between('medium', 'large')`
    display: flex;
    flex-direction: row;
  `}

  ${media.greaterThan('large')`
    display: flex;
    flex-direction: row;
  `}
`

export const Wrapper = styled.div`
  width: calc(100% - 380px);
  overflow: auto;
  padding: 25px;
  padding-bottom: 0;
  transition: all ease 0.3s;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ${media.lessThan('medium')`
    width: 100%;
  `}

  ${media.between('medium', 'large')`
    width: calc(100% - 380px);
  `}

  ${media.greaterThan('large')`
    width: calc(100% - 380px);
  `}
`

export const Cart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 380px;
  height: 100vh;
  background: #fdfdfa;
  overflow: none;
  transition: all ease 0.3s;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 25px;
    margin: 0;
    list-style: none;

    li {
      margin-left: 15px;

      .btn-currency {
        padding: 5px 10px;
        border-radius: 4px;
        margin-right: 4px;
        background: rgba(0, 0, 0, 0.1);
        font-size: 12px;
        cursor: pointer;
        transition: all ease 0.3s;

        &.actived {
          background: var(--primary);
          color: #fff;
        }
      }

      &.cart-count {
        text-align: center;
        line-height: 35px;
        font-weight: bold;
        font-size: 24px;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        background: var(--quaternary);
      }

      &.cart-close {
        display: none;

        ${media.lessThan('medium')`
          display: block;
        `}

        ${media.between('medium', 'large')`
          display: block;
        `}
      }

      a {
        font-size: 32px;
        color: #212121;
      }
    }
  }

  h3 {
    font-size: 26px;
    line-height: 28px;
    padding: 0 25px;
    margin-bottom: auto;
  }

  section {
    margin-bottom: auto;
    padding: 0 30px;
    max-height: 250px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  button {
    position: relative;
    width: 100%;
    background: var(--primary);
    padding: 20px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    color: #fff;
    font-weight: 600;
    text-align: left;

    &:focus {
      outline: none;
    }

    &:disabled {
      background: #616161;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 25px;
      transform: translateY(-50%);
      font-size: 26px;
    }
  }

  ${media.lessThan('medium')`
    width: 100%;
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  `}

  ${media.between('medium', 'large')`
    width: 100%;
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  `}

  ${media.greaterThan('large')`
    width: 380px;
  `}
`

export const Footer = styled.footer`
  background: #f1f5f8;
  padding: 30px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  h5 {
    margin: 0;
    color: #2b223d;
    font-weight: 600;
    margin-top: 5px;
  }

  h6 {
    font-weight: 300;
    color: #80798b;
  }

  img {
    width: 45px;
    filter: grayscale(100%);
    transition: all ease 0.3s;

    &:hover {
      filter: grayscale(0%);
    }
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;

    li {
      margin: 0 10px;

      a {
        font-size: 14px;
        font-weight: 600;
        color: var(--secondary);
        transition: all ease 0.3s;

        &:hover {
          color: #000;
        }
      }
    }
  }

  ${media.lessThan('medium')`
    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 12px;
    }

    ul {
      li {
        a {
          font-size: 10px;
        }
      }
    }

    img {
      width: 20px;
    }
  `}

  ${media.between('medium', 'large')`
    h5 {
      font-size: 16px;
    }

    h6 {
      font-size: 12px;
    }

    ul {
      margin: 0;
      li {
        a {
          font-size: 10px;
        }
      }
    }

    img {
      width: 20px;
    }
  `}
`

export const CartButton = styled.button`
  display: none;
  position: fixed;
  bottom: 110px;
  right: 17px;
  z-index: 98;

  border-radius: 70px;
  width: 60px;
  height: 60px;
  font-size: 32px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  background: var(--primary);
  transition: all 0.2s ease-in-out 0s;

  ${media.lessThan('medium')`
    display: block;
  `}

  ${media.between('medium', 'large')`
    display: block;
  `}
`
